import React, {useMemo} from 'react';
import {useTranslate} from 'react-admin';
import {forEach, isEmpty, split, map, isString} from 'lodash';
import PropTypes from 'prop-types';

function TranslatePattern({translateKey, options = {}}) {
  const translate = useTranslate();

  const translateString = useMemo(() => {
   const rawString = translate(translateKey);

   if (isEmpty(options)) return rawString;

   const parts = rawString.split(/(%{[^}]+})/);

   return map(parts, (part, index) => {
    const match = part.match(/%{([^}]+)}/);
    if (match) {
     const key = match[1];
     return options[key] || part;
    }
    return part;
   });
  }, [translateKey, options, translate]);


  return <React.Fragment>
   {isString(translateString) ? translateString : map(translateString, (item, key) => (<span key={key}>{item}&nbsp;</span>))}
  </React.Fragment>
}

TranslatePattern.propTypes = {
 translateKey: PropTypes.string.isRequired,
 options: PropTypes.objectOf(
   PropTypes.oneOfType([PropTypes.string, PropTypes.node])
 ),
};

TranslatePattern.defaultProps = {
 options: {},
};


export default React.memo(TranslatePattern)