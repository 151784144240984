import {localesConfig} from "./config";


const localesIsObject = localesConfig.localesIsObject
const localeAdapter = {
    toReact: (data) => localesIsObject ? data : localeAdapter._listToRow(data, localeAdapter.toReactRow),
    toDjango: (data) => localesIsObject ? data : localeAdapter._listToRow(data, localeAdapter.toDjangoRow),
    _listToRow: (data, f) => data.map((item)=>f(item)),
    toReactRow: (row) => {
        if(localesIsObject) return row
        let localeFields = {}
        for (const [key, value] of Object.entries(row)) {
            for (const locale of localesConfig.locales) {
                if (key.endsWith(`_${locale}`)) {
                    const field = key.split(`_${locale}`)[0]
                    const fl = localeFields[field] || {}
                    fl[locale] = value

                    localeFields[field] = fl
                }
            }
        }

        return {...row, ...localeFields}
    },
    toDjangoRow: (row) => {
        if(localesIsObject) return row
        const localeFields = new Set()
        for (const [key, value] of Object.entries(row)) {
            if (!value) continue
            if (!value.hasOwnProperty(localesConfig.defaultLocale)) continue

            for (const [key_sub, value_sub] of Object.entries(value)) {
                if (localesConfig.locales.includes(key_sub)) {
                    row[`${key}_${key_sub}`] = value_sub || undefined
                    localeFields.add(key)
                }
            }
        }

        let d = {}
        for (const i of localeFields) {
            d[i] = row[`${i}_${localesConfig.defaultLocale}`]
        }

        return {...row, ...d}
    },
}

export default localeAdapter
