//import * as React from "react";
import get from "lodash/get";

import GroupIcon from '@material-ui/icons/Group';
import {tableConfig} from "../tablesConfig/tableConfig";
import * as React from "react";
// import universalSimple from "../universalSimple";
//import CurrencyCrossRates from '../tablesConfig/CurrencyCrossRates/CurrencyCrossRates'

//-------------MAIN CONFIG--------------------------
function setApiForHost(nameApi) {

    let host = document.location.hostname
    let api;
    if (nameApi === 'apiAuthUrl') api = process.env.REACT_APP_API_AUTH
    else api = process.env.REACT_APP_API_URL
    if (api) {
        console.log('REACT_APP_API=========>', api)
        return api
    }

    let adminMappingConfig = localStorage.getItem('adminMappingConfig')
    if (adminMappingConfig) {
        adminMappingConfig = JSON.parse(adminMappingConfig)
        if (nameApi === 'apiAuthUrl')
            api = adminMappingConfig[nameApi] + "/api-public/e-trade/"
        else
            api = adminMappingConfig[nameApi] + "/api/e-trade/"
    }
    if (api) {
        console.log('adminMappingConfig==API=======>', api)
        return api
    }

    switch (host) {
        case 'localhost':
            /*if (nameApi === 'apiAuthUrl')
                api = 'https://client1-server.swiss-linker.ch/api-public/e-trade/'
            else
                api = 'https://client1-server.swiss-linker.ch/api/e-trade/'*/
            if (nameApi === 'apiAuthUrl')
                api = 'https://watch-demo-server.swiss-linker.ch/api-public/e-trade/'
            else
                api = 'https://watch-demo-server.swiss-linker.ch/api/e-trade/'
            /*if (nameApi === 'apiAuthUrl')
                api = 'https://dev-server.swiss-linker.ch/api-public/e-trade/'
            else
                api = 'https://dev-server.swiss-linker.ch/api/e-trade/'*/

            /*if (nameApi === 'apiAuthUrl')tge
                api = 'https://demo-server.swiss-linker.ch/api-public/e-trade/'
            else
                api = 'https://demo-server.swiss-linker.ch/api/e-trade/'*/

            /*if (nameApi === 'apiAuthUrl')
                api = 'https://flashub-server.swiss-linker.ch/api-public/e-trade/'
            else
                api = 'https://flashub-server.swiss-linker.ch/api/e-trade/'*/


            break
        case 'demo-admin.iam-trade.ch':
            /*if (nameApi === 'apiAuthUrl') api = 'https://demo.iam-trade.ch/'
            else*/
            api = 'https://demo.iam-trade.ch/'
            break
        case 'admin-etrade-sdf782vvd3567.stemsc.com':
            /*if (nameApi === 'apiAuthUrl') api = 'https://dev-server.swiss-linker.ch/api/e-trade/'
            else */
            api = 'https://dev-server.swiss-linker.ch/api/e-trade/'
            break
        case 'client1-admin.swiss-linker.ch':
            /*if (nameApi === 'apiAuthUrl') api = 'https://client1-server.swiss-linker.ch/api/e-trade/'
            else*/
            api = 'https://client1-server.swiss-linker.ch/api/e-trade/'
            break
        case 'dev-admin.swiss-linker.ch':
            api = 'https://dev-server.swiss-linker.ch/api/e-trade/'
            break
        case 'master-tool-admin.swiss-linker.ch':
            api = 'https://master-tool-server.swiss-linker.ch/api/e-trade/'
            break
        ///===================================
        case 'dev-pricing.zenit-auto.com': //ZENIT
            if (nameApi === 'apiAuthUrl') api = 'https://dev-api.zenit-auto.com/'
            else api = 'https://dev-services.zenit-auto.com/'
            break
        case 'prod-pricing.zenit-auto.com': //ZENIT new
            if (nameApi === 'apiAuthUrl') api = 'https://api.zenit-auto.com/'
            else api = 'https://prod-services.zenit-auto.com/'
            break

        default:
            api = 'error_host'
            break
    }
    console.log(host, 'API=========>', api)
    return api
}

export const config = {
    permission: process.env.REACT_APP_TYPE_PROJECT,//

    //keycloakDomain: process.env.REACT_APP_AUTH_DOMAIN,
    apiPrices: process.env.REACT_APP_TYPE_PROJECT === 'zenit'
        ? process.env.REACT_APP_API_PRICES //for ZENIT
        : setApiForHost('apiUrl'),
    apiAuthUrl: setApiForHost('apiAuthUrl'), //process.env.REACT_APP_API_AUTH,
    apiUrl: setApiForHost('apiUrl'), //process.env.REACT_APP_API_URL,
    apiSwager: process.env.REACT_APP_API_SWAGER,
    //apiGoogleMerchant: process.env.REACT_APP_API_GOOGLE_MERCHANT,
    //old apiPrompt: 'https://integration.iam-trade.ch/',
    apiPrompt: 'https://integration.swiss-linker.ch/api/',
    //old apiTranslate: 'https://integration.iam-trade.ch/',
    apiTranslate: 'https://integration.swiss-linker.ch/',

    apiForS3bucketImg: 'https://oz3cis9uwb.execute-api.eu-west-1.amazonaws.com/',
    apiForS3bucketLocalesRead: 'https://sam-deployment-swiss-linker-localizations3bucket-zcduq6xfnwwe.s3.eu-central-1.amazonaws.com/',
    apiForS3bucketLocalesUpload: process.env?.REACT_APP_STORAGE_IMAGE_URL || 'https://j7qmeo6gqk.execute-api.eu-central-1.amazonaws.com/Dev/',

    //cognito: false,
    //auth: false  // false is allowed only when cognito: false
    cognito: false,
    auth: true,  // false is allowed only when cognito: false
    gpt: "gpt-3.5-turbo",
}

// console.log(`config======== ${config}`)

//-------------LANGUAGE--------------------------
const locales = ['uk', 'en', 'ru', 'de', 'fr', 'it'];
const defaultLocale = 'uk';
const languages = [
    {locale: "uk", name: "Українська"},
    {locale: "en", name: "English 🇬🇧"},
    {locale: "ru", name: "Русский"},
    {locale: "de", name: "Germany"},
    {locale: "fr", name: "France"},
    {locale: "it", name: "Italian"},
]

function configLocales() {
    let myLanguages = languages
    let myDefaultLocale = defaultLocale

    let myLocales = null
    let langs = localStorage.getItem('auth')
    if (process.env.REACT_APP_API_LOCALES) {
        myLocales = process.env.REACT_APP_API_LOCALES.split(',')
    } else {
        if (langs) {
            langs = JSON.parse(langs)
            langs = get(langs, 'langs', null)
            if (langs) myLocales = langs
        }
    }

    if (myLocales && myLocales.length > 0) {
        myDefaultLocale = myLocales[0]
        myLanguages = []
        myLocales.forEach(it => {
            let res = languages.find(x => x.locale === it)
            if (res) myLanguages.push(res)
        })
    } else {
        myLocales = locales
    }

    let rrr = {
        localesIsObject: config.permission === 'swisslinker' ? true : false,
        defaultLocale: myDefaultLocale,
        locales: myLocales,
        languages: myLanguages
    }
    console.log('languages=config=======>', rrr)

    return rrr
}

export const localesConfig = configLocales()
//---------------------------------------

//types: int bool date text
//export const leftMenuConfig2 = [];
export const leftMenuConfig = [
    // {name: 'Products', icon: <GroupIcon/>},
    // {name: 'Currencies', icon: <GroupIcon/>},
    // {name: 'Brands', icon: <GroupIcon/>},
    // {name: 'Customers', icon: <GroupIcon/>},
    // {name: 'Delivery', icon: <GroupIcon/>},
    // {name: 'Unknown', icon: <GroupIcon/>},
    // {name: 'Empty', icon: <GroupIcon/>},
    // {name: 'Error', icon: <GroupIcon/>},
    // {name: 'Abend', icon: <GroupIcon/>},
    {name: 'Def', icon: <GroupIcon/>},
]

function getUrlByName(name) {

    let tmpTable
    tableConfig.tablesList.forEach((table) => {
        if (table.table === name) tmpTable = table
    })

    return tmpTable.base_url
}

export const apiPathConfig = {
    // 'Resource name': ['microservice', 'app', 'model, ]
    /*'startup-stages': ['settings', 'startup-stages', ''],
    'startup-status': ['settings', 'startup-status', ''],
    'user-position': ['settings', 'user-position', ''],
    'investment-status': ['settings', 'investment-status', ''],
    'user-title': ['settings', 'user-title', '', ''],
    'feedback-subjects': ['settings', 'feedback-subjects', ''],
    'article-image': ['settings', 'article-image', ''],
    'section-image': ['settings', 'section-image', ''],*/

    getUrl: (name, type = '') => {
        // const params = apiPathConfig[name]
        //console.log('getUrl====>', name, type)
        switch (name) {


            // case 'universal': return config.apiUrl + 'api/react-admin/main/CurrencyCrossRates';
            //default: return config.apiUrl+getUrlByName(name);


            //############### ADMIN ######################
            // /api/react-admin/main/Interfaces
            // /api/react-admin/main/UsersInterfaces
            // /api/react-admin/auth/User
            case 'dInterfaces':
                return config.apiAuthUrl + 'api/react-admin/main/Interfaces';
            case 'dUsersInterfaces':
                return config.apiAuthUrl + 'api/react-admin/main/UsersInterfaces';
            case 'dUser':
                return config.apiAuthUrl + 'api/react-admin/auth/User';
            //################## E-TRADE ADMIN DEMO #############################
            //------------Классификаторы + CONFIG-----------------------
            case 'dConfig':
                return config.apiUrl + 'api/react-admin/main/Config';
            case 'dCountries':
                return config.apiUrl + 'api/react-admin/main/Countries';
            case 'dCurrencies':
                return config.apiUrl + 'api/react-admin/main/Currencies';

            case 'dMenuItems':
                return config.apiUrl + 'api/react-admin/main/MenuItems';
            case 'dBlogArticle':
                return config.apiUrl + 'api/react-admin/main/BlogArticle';


            case 'dPriceTypes':
                return config.apiUrl + 'api/react-admin/main/PriceTypes';
            case 'dHoliday':
                return config.apiUrl + 'api/react-admin/main/Holiday';
            case 'dAvailableLanguage':
                return config.apiUrl + 'api/react-admin/main/AvailableLanguage';
            case 'dCompanyPhone':
                return config.apiUrl + 'api/react-admin/main/CompanyPhone';
            case 'dCompanyEmails':
                return config.apiUrl + 'api/react-admin/main/CompanyEmails';

            case 'dMessenger':
                return config.apiUrl + 'api/react-admin/main/Messenger';
            case 'dCompanyMessenger':
                return config.apiUrl + 'api/react-admin/main/CompanyMessenger';
            case 'dCompanyBranchWorkingHours':
                return config.apiUrl + 'api/react-admin/main/CompanyBranchWorkingHours';
            case 'dCompanyBranchPhone':
                return config.apiUrl + 'api/react-admin/main/CompanyBranchPhone';
            case 'dCompanyBranch':
                return config.apiUrl + 'api/react-admin/main/CompanyBranch';
            case 'dPhoneMessenger':
                return config.apiUrl + 'api/react-admin/main/PhoneMessenger';

            case 'dManager':
                return config.apiUrl + 'api/react-admin/main/Manager';
            case 'dManagerCustomer':
                return config.apiUrl + 'api/react-admin/main/ManagerCustomer';
            case 'dCurrencyRates':
                return config.apiUrl + 'api/react-admin/main/CurrencyRates';
            case 'dCurrencyCrossRates':
                return config.apiUrl + 'api/react-admin/main/CurrencyCrossRates';

            case 'dSuppliers':
                return config.apiUrl + 'api/react-admin/main/Suppliers';
            case 'dExternalSuppliers':
                return config.apiUrl + 'api/react-admin/main/ExternalSuppliers';
            case 'dSuppliersWarehouses':
                return config.apiUrl + 'api/react-admin/main/SuppliersWarehouses';
            case 'dWarehouseStock':
                return config.apiUrl + 'api/react-admin/product_page/WarehouseStock';

            case 'dColor':
                return config.apiUrl + 'api/react-admin/product_page/Color';
            case 'dVariantsColors':
                return config.apiUrl + 'api/react-admin/product_page/VariantColor';
            //------------Translate-----------------------

            case 'translate':
                return config.apiTranslate + 'api-public/integration/ai/translate';
            //------------COMMON-----------------------
            case 'dFeatureType':
                return config.apiUrl + 'api/react-admin/product_page/FeatureType';
            case 'dFeatureValidValue':
                return config.apiUrl + 'api/react-admin/product_page/FeatureValidValue';
            case 'dProductsFeatureValue':
                return config.apiUrl + 'api/react-admin/product_page/ProductsFeatureValue';
            case 'dProductsGroupFeatureType':
                return config.apiUrl + 'api/react-admin/product_page/ProductsGroupFeatureType';
            case 'dProductsGroupRelations':
                return config.apiUrl + 'api/react-admin/main/ProductGroupRelations';


            case 'dBrands':
                return config.apiUrl + 'api/react-admin/main/Brands';
            case 'dBrandImage':
                return config.apiUrl + 'api/react-admin/main/BrandImage';

            case 'dNews':
                return config.apiUrl + 'api/react-admin/main/News';
            case 'dNewsType':
                return config.apiUrl + 'api/react-admin/main/NewsType';

            case 'dCustomers':
                return config.apiUrl + 'api/react-admin/main/Customers';
            case 'dProducts':
                return config.apiUrl + 'api/react-admin/main/Products';
            case 'dRelatedProducts':
                return config.apiUrl + 'api/react-admin/main/RelatedProducts';
            case 'dPersonalDiscountsByProduct':
                return config.apiUrl + 'api/react-admin/main/PersonalDiscountsByProduct';

            case 'dProductsGroup':
                return config.apiUrl + 'api/react-admin/main/ProductsGroup';

            case 'dProductsGroupTree':
                return config.apiUrl + 'api/get-product-group-tree2';


            case 'dOrders':
                return config.apiUrl + 'api/react-admin/main/Order';

            // case 'dPrompts': return config.apiUrl + 'integration/admin/prompts';
            case 'dPrompts':
                return config.apiPrompt + 'integration/admin/prompts';
            case 'dPromptIndustries':
                return config.apiPrompt + 'integration/admin/prompt_industries';
            case 'dPromptTypes':
                return config.apiPrompt + 'integration/admin/prompt_types';

            case 'dPromotion':
                return config.apiUrl + 'api/react-admin/promotion/Promotion';
            case 'dPromotionBadge':
                return config.apiUrl + 'api/react-admin/promotion/PromotionBadge';
            case 'dDiscountConditionsDocument':
                return config.apiUrl + 'api/react-admin/promotion/DiscountConditionsDocument';
            case 'dPromotionalGood':
                return config.apiUrl + 'api/react-admin/promotion/PromotionalGood';

            // case 'dPromptTypes': return config.apiUrl + 'api/react-admin/product_page/ProductReview';

            //----------------
            case 'dOrdersForChart':
                return config.apiUrl + 'api/reports/orders';
            //      https://demo.iam-trade.ch/api/react-admin/product_page/VariantProduct
            case 'dVariantProductImage':
                return config.apiUrl + 'api/react-admin/product_page/VariantProductImage';
            case 'dCharacteristicProduct':
                return config.apiUrl + 'api/react-admin/product_page/CharacteristicProduct';
            case 'dVariantProduct':
                return config.apiUrl + 'api/react-admin/product_page/VariantProduct';
            // https://demo.iam-trade.ch/api/react-admin/product_page/ProductsPrice?product=85274&price_type=8
            case 'dProductsPrice':
                return config.apiUrl + 'api/react-admin/product_page/ProductsPrice';
            case 'dProductToProductGroup':
                return config.apiUrl + 'api/react-admin/main/ProductToProductGroup';

            case 'dSeoKeyWords':
                return config.apiUrl + 'api/react-admin/seo/SeoKeyWords';

            case 'dSizeChart':
                return config.apiUrl + 'api/react-admin/product_page/SizeChart';
            case 'dSizeSpecification':
                return config.apiUrl + 'api/react-admin/product_page/SizeSpecification';
            case 'dSizeProduct':
                return config.apiUrl + 'api/react-admin/product_page/SizeProduct';

            case 'dUnitsOfMeasurement':
                return config.apiUrl + 'api/react-admin/main/UnitsOfMeasurement';

            case 'dPaymentProvider':
                return config.apiUrl + 'api/react-admin/payment/PaymentProvider';
            case 'dPayment':
                return config.apiUrl + 'api/react-admin/payment/Payment';

            //################## ZENIT #############################
            case 'zCustomers':
                return config.apiUrl + 'api/react-admin/main/Customers';
            case 'dCustomerPhone':
                return config.apiUrl + 'api/react-admin/main/CustomerPhone';
            case 'dCustomerUser':
                return config.apiUrl + 'api/react-admin/main/CustomerUser';

            case 'partner':
                return config.apiUrl + 'api/react-admin/main/Partner';
            case 'partnerAddress':
                return config.apiUrl + 'api/react-admin/main/PartnerAddress';
            case 'delivery':
                return config.apiUrl + 'api/react-admin/main/Delivery';

            case 'customer_z':
                return config.apiPrices + 'api/pricing/admin/customer';
            case 'discountProperty':
                return config.apiPrices + 'api/pricing/admin/discount_property';
            case 'discountPropertyValue':
                return config.apiPrices + 'api/pricing/admin/discount_property_valid_value';
            case 'currency_z':
                return config.apiPrices + 'api/pricing/admin/currency';
            case 'customer_property':
                return config.apiPrices + 'api/pricing/admin/customer_property_value';
            case 'product_property':
                return config.apiPrices + 'api/pricing/admin/product_property_value';
            case 'discount_relationship':
                return config.apiPrices + 'api/pricing/admin/discount_relationship';

            case 'discount_rules':
                return config.apiPrices + 'api/pricing/admin/discount_rules';
            case 'brand_discount_by_rule':
                return config.apiPrices + 'api/pricing/admin/brand_discount_by_rule';
            case 'brand_z':
                return config.apiPrices + 'api/pricing/admin/brand';
            case 'price_row_z':
                return config.apiPrices + 'api/pricing/admin/price_row';
            case 'product_discount_by_rule':
                return config.apiPrices + 'api/pricing/admin/product_discount_by_rule';
            case 'product_z':
                return config.apiPrices + 'api/pricing/admin/product';
            case 'discount_values_by_rule':
                return config.apiPrices + 'api/pricing/admin/discount_values_by_rule';
            case 'complicated_discount_by_rule':
                return config.apiPrices + 'api/pricing/admin/complicated_discount_by_rule';
            case 'customer_discount_by_rule':
                return config.apiPrices + 'api/pricing/admin/customer_discount_by_rule';


            case 'gift_pool':
                return config.apiPrices + 'api/pricing/admin/gift_pool';
            case 'gift':
                return config.apiPrices + 'api/pricing/admin/gift';

            case 'test_price':
                return config.apiPrices + 'api/pricing/admin/full-prices';//api/pricing/full-prices-by-price-rows

            case 'createPartner':
                return config.apiPrices + 'api/pricing/admin/full-prices';//api/pricing/full-prices-by-price-rows

            case 'edit_sheduler':
                return config.apiPrices + 'api/pricing/admin/full-prices';

            default:
                return config.apiPrices + getUrlByName(name);
            // default: return `${config.apiUrl}`;

        }

    },

    getApiUrl: () => config.apiUrl,
    getAuth: () => config.auth,
    getCognito: () => config.cognito,
}

export default config
