import CategoryIcon from '@material-ui/icons/Bookmark';

import ProductsGroupList from './ProductsGroupList';

const resource = {
    list: ProductsGroupList,
    icon: CategoryIcon,
};
export default resource;

