export const transEn = {
    myLocal: {
        columns: 'Columns',
        hide_filter: 'Pre-Filters', //'Hide Filter',
        show_filter: 'Pre-Filters', //'Show Filter',
    },
    dbFields: {},
    remove_alternate_parent: "Remove alternative relationship to %{parent_name}",
    replace_alternate_to_parent: "Make %{parent_name} the direct parent and make %{alternate_parent_name} the alternate relationship",
    replace_parent: "Make %{parent_name} the direct parent and remove the relationship with %{alternate_parent_name}",
    remove_parent: "Remove direct relationship to %{parent_name}",
    make_alternative: "Make %{parent_name} an alternate parent",
    make_direct: "Make %{parent_name} a direct parent",
    undo_action: 'Undo action',
    categories_tree: 'Categories Tree',
    edit_category: 'Edit Category',
    show_hidden_categories: 'Show hidden for users categories',
    hidden_for_user_category: 'Hidden',
    show_category_for_user: 'Show category for user',
    hide_category_for_user: 'Hide category for user',
    mixed: 'Mixed',
    group_only: 'Only categories',
    item_only: 'Only products',
    'Hierarchy type': 'Hierarchy type',
    'Hide category and its products': 'Hide category and its products',
    'Display On Main Page': 'Display On Main Page',
    'Display in Header and/or Catalogue tree': 'Display in Header and/or Catalogue tree',
    'Use as Filter in listing': 'Use as Filter in listing',
    hidden_for_user_description: 'If enabled, the category and its subcategories will be hidden on the website, along with all assigned products, including search results.',
    show_on_main_description: 'Specify if the category should be displayed in the main page section of the site. These are usually the categories promoted by the company. ',
    show_on_menu_description: 'Specify if the category should be displayed in the site\'s menu, including the header or category tree, if supported by the design.',
    show_on_filter_description: 'Specify if the category should appear as a filter in the listing page. For example, searching for "Filters" might display categories like "Air," "Oil," and "AC" in the listing filters. ',
    'Display type': 'Display type',
    show_category: 'Show category',
    hide_category_show_children: 'Don\'t show category BUT show nested',
    hide_category_and_children: 'Don\'t show category and hide all subcategories',
}
