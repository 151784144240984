import {useRecordContext, useTranslate, useUpdate} from "react-admin";
import * as React from "react";
import {Box, Button, Menu, MenuItem} from "@material-ui/core";
import {getStatusById} from "../_constants/choices";
import get from 'lodash/get';
import {isFunction} from 'lodash';

const SelectAndSaveChoices = (props) => {
    const {
        source, statuses, resource,
        showTextValue, disableCondition,
        width, edit = false, color = false,
        is_field_no_edit = false,
        circle = false,
        send_only_this_field = true,
         hasPopover = true,
      callbackUpdate,
      hasInvertValue = false
    } = props;

    const translate = useTranslate();

    let editEl = edit;
    // const refresh = useRefresh();
    const [update, {loading, error}] = useUpdate();

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        event.preventDefault();
        event.stopPropagation();
        if (editEl) setAnchorEl(event.currentTarget);
    };


    const handleClose = (event) => {
        setAnchorEl(null);
        event.preventDefault();
        event.stopPropagation();
    };
    const onClick = (event, v) => {
        handleClose(event);
        const data = send_only_this_field ? {id} : record
        data[source] = v
        update(resource, id, data, record);
    }

    const handleUpdate = (event) => {
        event.preventDefault();
        event.stopPropagation();
        const data = send_only_this_field ? {id} : record

        data[source] = !get(record, source, null);

        update(resource, id, data, record)

        if (isFunction(callbackUpdate)) callbackUpdate()

    }

    const record = useRecordContext(props);
    if (!record) return null;
    const {id} = record || {};
    const fieldValue = get(record, source, null)
    const status = hasInvertValue ? !fieldValue : fieldValue

    if (is_field_no_edit) {
        editEl = get(record, is_field_no_edit, null);
        editEl = editEl ? false : true
    }


    //if (editEl === false && status === null) return null;

    if (error) {
        return <p>ERROR</p>;
    }
    const result = getStatusById(status, statuses);
    const {id: idRes, name, img} = result || {};
    // const {id: idRes, name, img, color} = result || {};
    return <div style={{cursor: "pointer", textAlign: '-webkit-center', padding: 8}} onClick={hasPopover ? handleClick : handleUpdate}>
        {circle
            ?
            <Box
                //onClick={handleClick}
                title={name}
                style={{
                    width: 15, height: 15,
                    borderRadius: '50%',
                    //border: '1px solid black',
                    backgroundColor: status !== null
                        ? status
                            ? circle === 'inv' ? '#e88b7d' : '#a4e87d'
                            : circle === 'inv' ? '#a4e87d' : '#e88b7d'
                        : 'magenta'
                }}
            />
            :
            <Button
                style={{
                    backgroundColor: showTextValue && '#eee',
                    cursor: "pointer",
                    color: color
                        ? idRes ? color : 'red'
                        : 'inherit',
                    width: width && width,
                    padding: '4px 4px 2px 4px',
                    fontSize: 12,
                    opacity: (edit && !editEl) ? 0.3 : 1,
                }}
                title={`${'id: '}${status} / ${'name: '}${name}`}
                id="basic-button"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
                // disabled={loading || (disableCondition && !disableCondition(record))}
                disabled={loading || disableCondition}
            >
                {showTextValue ? translate(name) : img}
            </Button>}

        <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
                'aria-labelledby': 'basic-button',
            }}
        >
            {statuses.map(v => (
                <MenuItem style={{color: v.color && v.color}}
                          key={v.id}
                          onClick={(event) => onClick(event, v.id)}
                >
                    {v.image} {translate(v.name)}
                </MenuItem>))}
        </Menu>
    </div>;
}

export default SelectAndSaveChoices
