import {fetchJson as httpClient} from "../utils/keycloak";
import {config} from "../utils/config";
import {fetchUtils} from "react-admin";
import get from "lodash/get";
import {MAP_APP_MODEL_BY_RESOURCE} from '../utils/constants';


async function readFileAsDataURL(file) {
    const result_base64 = await new Promise((resolve) => {
        let fileReader = new FileReader();
        fileReader.onload = (e) => resolve(fileReader.result);
        fileReader.readAsDataURL(file);
    });
    // console.log('result_base64=========>', result_base64); // aGV5IHRoZXJl...
    return result_base64;
}

export async function imageUpload(base64) {
    const res = await httpClient(`${config.apiUrl}/api-public/v1/investment/upload_file/`, {
        method: 'post',
        body: JSON.stringify({
            b64: base64
        })
    })
    const {json} = res || {};
    const {url} = json || {};
    //console.log('URL==============>', url);
    return url;

}

export const transform = async (resource, data) => {
    let dt = data;
    //console.log('data==============>', data);
    for (const [key, value] of Object.entries(data)) {
        const {rawFile} = value || {};
        if (rawFile) {
            if (rawFile instanceof File) {
                let dataURL = await readFileAsDataURL(rawFile);
                if (dataURL) dt[key] = dataURL;
            }
        }
    }
    return dt;
}

//==========================LOCALES=====================================
async function uploadImageS3Locales(url, data, type) {
    const myHeaders = new Headers();
    myHeaders.append('Accept', '*/*');
    // myHeaders.append('Content-Type', 'image/jpeg');
    myHeaders.append('Content-Type', type);
    return fetchUtils.fetchJson(url, {
        method: 'put',
        body: data,
        headers: myHeaders,
    })
}

export async function getTokenForBucketLocales(dirOnS3bucket, name) {
    let url = `${config.apiForS3bucketLocalesUpload}get-presigned-url?fileName=${dirOnS3bucket}/${name}&type=localization`
    const res = await httpClient(`${url}`, {method: 'get'})
    return get(res, "json", null);

}

export const copyToS3bucketLocales = async (dirOnS3bucket, name, data) => {
    let bucketUrl = await getTokenForBucketLocales(dirOnS3bucket, name)
    if (bucketUrl) {
        const {uploadURL} = bucketUrl || {};
        //const urlImg = uploadURL.split('?')[0]

        // console.log('bucketUrl=========>', bucketUrl)
        // const fd = new FormData() // make form data
        // fd.append("file", rawFile) // append file to form
        const result = await uploadImageS3Locales(uploadURL, data, 'application/json')
        if (result.status >= 200 && result.status < 300) {
            //set(dt, "attributes.animation", url + rawFile.name)
            return 'OK'
        } else {
            return 'ERROR STATUS'
        }
    }
    return 'ERROR bucketUrl'
}

//=========================IMAGE======================================
async function uploadImageS3(url, data, type) {
    // console.log('url============>', url)
    // console.log('data============>',data)
    // console.log('type============>',type)
    const myHeaders = new Headers();
    myHeaders.append('Accept', '*/*');
    const blob = new Blob([data], {
        type: type
    });
    // myHeaders.append('Content-Type', 'image/jpeg');
    myHeaders.append('Content-Type', type);
    return process.env?.REACT_APP_STORAGE_IMAGE_URL ? httpClient(url, {
        method: 'put',
        body: blob,
        headers: myHeaders,
    }) : fetchUtils.fetchJson(url, {
        method: 'put',
        body: blob,
        headers: myHeaders,
    })


}

export async function getTokenForBucket2(name, type, queryOptions) {
    // let url = 'https://oz3cis9uwb.execute-api.eu-west-1.amazonaws.com/uploads'
    // let url = `${config.apiForS3bucketImg}uploads`

    const params = new URLSearchParams({
        fileName: name,
        ContentType: type,
        ...(process.env?.REACT_APP_STORAGE_IMAGE_URL ? queryOptions : {})
    });

    let url = `${config.apiForS3bucketLocalesUpload}get-presigned-url?${params.toString()}`

    const res = await httpClient(`${url}`, {method: 'get'})

    // const {json} = res || {};
    // const {url} = json || {};
    return get(res, "json", null);

}

/*
GET https://j7qmeo6gqk.execute-api.eu-central-1.amazonaws.com/Dev/get-presigned-url?fileName=path/test.json&ContentType=application/json
Content-Type: application/json
 */
async function dataUrlToFile(dataUrl, fileName, type){
    const res = await fetch(dataUrl);
    const blob = await res.blob();
    return new File([blob], fileName, { type: type });
}
export const exchangeImage = async (st, lang) => {
//export async function exchangeImage(st) {
    let clearSt = st
    let stBase64 = ''
    let isBase64 = false
    // console.log('clearSt===1=======>', clearSt)
    if (clearSt) {
        while (clearSt.indexOf('src="data:image/') >= 0) {
            isBase64 = true
            let ttt = clearSt.split('src="data:image/').pop().split('"').shift();
            let type = clearSt.split('src="data:').pop().split(';').shift();
            let ext = type.split('/')
            stBase64 = 'data:image/' + ttt
            let rs =  await dataUrlToFile(stBase64, `img.${ext[1] ? ext[1] : 'png'}`, type)
            let url = await copyToS3bucketIm('rich_text', {image: rs})
            clearSt = clearSt.replace('data:image/' + ttt, url)
        }
    }
    // console.log('clearSt===2=======>', clearSt)
    return isBase64 ? {clearSt, lang} : null
}

export const copyToS3bucketIm = async (resource, form) => {
    const {image: rawFile} = form
    if (rawFile instanceof File) {
        let tm = rawFile.name.split('.')
        tm[0] = `${tm[0]}_${Date.now()}`
        tm = tm.join('.')
        let fileName = resource + '/' + tm;
        console.log('fileName========>', fileName)
        let bucketUrl = await getTokenForBucket2(fileName, rawFile.type)
        if (bucketUrl) {
            const {uploadURL} = bucketUrl || {};
            const urlImg = uploadURL.split('?')[0]
            const result = await uploadImageS3(uploadURL, rawFile, rawFile.type)
            if (result.status >= 200 && result.status < 300) {
                return urlImg
            } else return ''
        } else return ''
    } else return rawFile
}

export const copyToS3bucket = async (resource, data) => {
    //async function transform(data) {
    // const notAllowedFields = ["Content-Type"]

    let dt = data;
    for (const [key, value] of Object.entries(data)) {
        const {rawFile, src} = value || {};
        const {type} = rawFile || {};
        if (key.indexOf('_raw_file') > 1) {
            delete dt[key]
            continue
        }
        //if (src) console.log(key, 'src.indexOf=========>', value)
        if (rawFile && key.indexOf('_raw_file') < 2) {
            if (key === 'bunner_video_l' && src) {
                dt[key] = {rawFile: {type: 'video'}, src: src}
            } else if (rawFile instanceof File) {

                const resourceOptions = MAP_APP_MODEL_BY_RESOURCE?.[resource] || {};

                let tm = rawFile.name.split('.')
                tm[0] = `${tm[0]}_${Date.now()}`
                tm = tm.join('.')
                let fileName = resource + '/' + tm;
                let bucketUrl = await getTokenForBucket2(fileName, type, {...resourceOptions, fieldName: key, rowId: data?.id || -1})
                if (bucketUrl) {
                    const {uploadURL} = bucketUrl || {};
                    const urlImg = uploadURL.split('?')[0]

                   const result = await uploadImageS3(uploadURL, rawFile, type)

                    if (result.status >= 200 && result.status < 300) {
                        //set(dt, "attributes.animation", url + rawFile.name)
                        dt[key] = result?.json?.image_relative || urlImg

                    } else dt[key] = null; //set(dt, "attributes.animation", '');
                } else dt[key] = null; //set(dt, "attributes.animation", "")
            } else if (src && src.indexOf('data:') === 0) {
                let bucketUrl = await imageUpload(src);
                if (bucketUrl) dt[key] = {rawFile: {type: type}, src: bucketUrl}
            }
        } else if (typeof value === 'string' && value.indexOf('src="data:image/') > 0) {
            let vvv = value;
            while (vvv.indexOf('src="data:image/') >= 0) {
                let ttt = vvv.split('src="data:image/').pop().split('"').shift();
                let bucketUrl = await imageUpload('data:image/' + ttt);
                //let bucketUrl2 = await imageUpload2('data:image/' + ttt);
                if (bucketUrl) vvv = vvv.replace('data:image/' + ttt, bucketUrl)
            }
            dt[key] = vvv;
        }
    }
    //console.log('it=========>', dt)
    return dt;
}

//==============Catapult===========================================
/*
export async function imageUpload(base64) {
    const res = await httpClient(`${config.apiUrl}/api-public/v1/investment/upload_file/`, {
        method: 'post',
        body: JSON.stringify({
            b64: base64
        })
    })
    const {json} = res || {};
    const {url} = json || {};
    //console.log('URL==============>', url);
    return url;

}
export async function getTokenForBucket2(name) {
    //POST '/api-public/v1/investment/presigned_url/'
    // let url = config.apiUrl.replace('universal-gql/', '')
    let url = `${config.apiUrl}${'/api-public/v1/investment/presigned_url/'}`
    const res = await httpClient(`${url}`, {
        method: 'post',
        /!*body: {
            "path": name,
        }*!/
        body: JSON.stringify({
            "path": name,
        })
    })
    // const {json} = res || {};
    // const {url} = json || {};
    return get(res, "json", null);

}

async function uploadImageS3(url, formData) {
    const myHeaders = new Headers();
    myHeaders.append('Accept', '*!/!*');
    return fetchUtils.fetchJson(url, {
        method: 'post',
        body: formData,
        headers: myHeaders,
    })
}

export const copyToS3bucket = async (data) => {
    //async function transform(data) {
    // const notAllowedFields = ["Content-Type"]
    const notAllowedFields = []
    let dt = data;
    for (const [key, value] of Object.entries(data)) {
        const {rawFile, src} = value || {};
        const {type} = rawFile || {};
        if (src) console.log(key, 'src.indexOf=========>', value)
        if (rawFile) {
            if (key === 'bunner_video_l' && src) {
                dt[key] = {rawFile: {type: 'video'}, src: src}
            } else if (rawFile instanceof File) {
                /!*let dataURL = await readFileAsDataURL(rawFile);
                let bucketUrl = await imageUpload(dataURL);
                if (bucketUrl) dt[key] = {rawFile: {type: type}, src: bucketUrl}
                else dt[key] = {rawFile: {type: type}, src: dataURL}*!/
                // let dataURL = await readFileAsDataURL(rawFile);
                // console.log('dataURL==Base64=======>', dataURL)

                let bucketUrl = await getTokenForBucket2(key + '/' +rawFile.name)
                if (bucketUrl) {
                    const {url, fields} = bucketUrl || {};
                    const {key: keyS3} = fields || {};
                    // console.log(url, 'bucketUrl=========>', fields)
                    const fd = new FormData() // make form data
                    for (const key in fields) {
                        if (!notAllowedFields.includes(key)) {
                            fd.append(
                                key,
                                fields[key]
                            )
                        }
                    }
                    fd.append("file", rawFile) // append file to form
                    const result = await uploadImageS3(url, fd)
                    if (result.status === 204) {
                        //set(dt, "attributes.animation", url + rawFile.name)
                        dt[key] = {rawFile: {type: 'video'}, src: url + keyS3}
                    } else dt[key] = null; //set(dt, "attributes.animation", '');
                } else dt[key] = null; //set(dt, "attributes.animation", "")
            } else if (src && src.indexOf('data:') === 0) {
                let bucketUrl = await imageUpload(src);
                if (bucketUrl) dt[key] = {rawFile: {type: type}, src: bucketUrl}
            }
        } else if (typeof value === 'string' && value.indexOf('src="data:image/') > 0) {
            let vvv = value;
            while (vvv.indexOf('src="data:image/') >= 0) {
                let ttt = vvv.split('src="data:image/').pop().split('"').shift();
                let bucketUrl = await imageUpload('data:image/' + ttt);
                //let bucketUrl2 = await imageUpload2('data:image/' + ttt);
                if (bucketUrl) vvv = vvv.replace('data:image/' + ttt, bucketUrl)
            }
            dt[key] = vvv;
        }
    }
    //console.log('it=========>', dt)
    return dt;
}
*/
//=================================================================
/*
export const copyToS3bucket = async (data) => {
    //async function transform(data) {
    let dt = data;
    for (const [key, value] of Object.entries(data)) {
        const {rawFile, src} = value || {};
        const {type} = rawFile || {};
        if(src) console.log(key,'src.indexOf=========>', src.indexOf('data:'))
        if (rawFile) {
            if (rawFile instanceof File) {
                let dataURL = await readFileAsDataURL(rawFile);
                //dt[key] = {rawFile: {type: type}, src: dataURL}
                let bucketUrl = await imageUpload(dataURL);
                if (bucketUrl) dt[key] = {rawFile: {type: type}, src: bucketUrl}
                else dt[key] = {rawFile: {type: type}, src: dataURL}
            } else if(src && src.indexOf('data:') === 0){
                let bucketUrl = await imageUpload(src);
                if (bucketUrl) dt[key] = {rawFile: {type: type}, src: bucketUrl}
            }
        } else if (typeof value === 'string' && value.indexOf('src="data:image/') > 0) {
            let vvv = value;
            while (vvv.indexOf('src="data:image/') >= 0) {
                let ttt = vvv.split('src="data:image/').pop().split('"').shift();
                let bucketUrl = await imageUpload('data:image/' + ttt);
                //let bucketUrl2 = await imageUpload2('data:image/' + ttt);
                if (bucketUrl) vvv = vvv.replace('data:image/' + ttt, bucketUrl)
            }
            dt[key] = vvv;
        }
    }
    //console.log('it=========>', dt)
    return dt;
}
*/

/*const imageUpload2 = async (base64) => {
    //yarn add aws-sdk
    const AWS = require('aws-sdk');

    const {
        ACCESS_KEY_ID = "AKIA4K3UBHDHMXNV6KEK",
        SECRET_ACCESS_KEY = "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",
        REACT_APP_COGNITO_REGION,
        S3_BUCKET = "investment"
    } = process.env;

    AWS.config.setPromisesDependency(require('bluebird'));
    AWS.config.update({
        accessKeyId: ACCESS_KEY_ID,
        secretAccessKey: SECRET_ACCESS_KEY,
        region: REACT_APP_COGNITO_REGION
    });

    const s3 = new AWS.S3();

    const base64Data = new Buffer.from(base64.replace(/^data:image\/\w+;base64,/, ""), 'base64');

    const type = base64.split(';')[0].split('/')[1];

    const userId = 1;

    const params = {
        Bucket: S3_BUCKET,
        Key: `${userId}.${type}`, // type is not required
        Body: base64Data,
        ACL: 'public-read',
        ContentEncoding: 'base64', // required
        ContentType: `image/${type}` // required. Notice the back ticks
    }

    let location = '';
    let key = '';
    try {
        const {Location, Key} = await s3.upload(params).promise();
        location = Location;
        key = Key;
    } catch (error) {
        console.log('error============>', error);
    }

    console.log('result================>', location, key);

    return location;

}*/
