import * as React from 'react';
import {
    TabbedForm,
    FormTab,
    useLocale,
    useTranslate,
    BooleanInput,
    AutocompleteInput,
    ReferenceInput,
    useGetList, NumberInput, Title, FormDataConsumer,
    //useListContext,
    //useEditContext,
} from 'react-admin';
import {Box, useMediaQuery, Tooltip} from "@material-ui/core";
import PostEditDeleteActions from "../../_common/PostEditDeleteActions";

import {MyTranslatableInputs} from "../../_common/MyTranslatableInputs";
import {useEffect, useMemo, useState} from 'react';
import {ImageInputComp} from "../../_common/CompReact";
import {TranslateProduct} from "../../_common/CompForApi/utilsAPI";
import {MyTranslateIcons} from "../../icons/MyTranslateIcons";
import Seo from "../../_common/Seo";
import {getPromptForTable} from "../../_common/CompFormat/utils";
import {MyTranslatableInputsRich} from "../../_common/MyTranslatableInputsRich";
import {BaseTab} from "./BaseTab";
import FootherEdit from "../../_common/Headers/FootherEdit";
import SectionNameInEdit from "../../_common/Headers/SectionNameInEdit";
import HeaderRelationTable from "../../_common/Headers/HeaderRelationTable";
import {Link} from '@mui/material';
import LaunchIcon from '@mui/icons-material/Launch';
import {map} from 'lodash';
import {CATEGORIES_DISPLAY_TYPES, CATEGORIES_TYPES} from '../../utils/constants';

const BlockEditImg = props => {
    // const translate = useTranslate();
    //const {related_id} = props
    // const locale = useLocale();
    // const formEdit = useForm();

    return (
        <Box p="1em" fullWidth>
            <Box mb="1em" flex={1} display="flex" alignItems={"top"}>
                <Box flex={1} alignItems={"top"}
                     style={{border: '1px solid #ddd', borderRadius: 16}}>
                    <ImageInputComp
                        maxSize={500000}
                        heightImg={180}
                        source={'image'}
                        /*label={`${'New'} ${'image'}`}*/
                        label={'Main page banner'}
                        accept="image/*"
                        //validate={required()}
                        defaultValue={''}
                        placeholder={<p>Drop your <b>Main page banner</b> here (500kb max)</p>}
                    />
                </Box>
            </Box>
        </Box>
    )
}

const BlockEdit1 = props => {
    const translate = useTranslate();
    const locale = useLocale();
    const {promptData, isSmall, id} = props

    const typesOptions = useMemo(
      () => map(CATEGORIES_TYPES, (type, key) => ({ id: key, name: type })),
      []
    );

    const typesDisplayOptions = useMemo(
      () => map(CATEGORIES_DISPLAY_TYPES, (type, key) => ({ id: key, name: type })),
      []
    );

    return (
        <Box p="1em" fullWidth>
            <Box style={{display: isSmall ? 'grid' : 'flex'}} alignItems={"top"}>
                <Box flex={1} mr={"1em"}>
                    <ReferenceInput
                        label={translate('Parent')}
                        source={'parent'}
                        reference={'dProductsGroup'}
                        //filter={{question$id: id}}
                    >
                        <AutocompleteInput optionText={`${'name'}[${locale}]`} resettable
                                           variant={"outlined"} fullWidth/>
                    </ReferenceInput>
                </Box>
                <Box flex={1}>
                        <AutocompleteInput
                          label='Hierarchy type'
                          defaultValue='MIXED'
                          choices={typesOptions}
                          source='hierarchy_type'
                          resettable
                          variant="outlined"
                          fullWidth/>
                </Box>

            </Box>

          <Box style={{display: isSmall ? 'grid' : 'flex'}} alignItems={"top"}>
            <Box flex={1} mr={"1em"}>
              <AutocompleteInput
                label='Display type'
                defaultValue='SHOW_CATEGORY'
                choices={typesDisplayOptions}
                source='show_type'
                resettable
                variant="outlined"
                fullWidth/>
            </Box>
            <Box flex={1}>
            </Box>

          </Box>

            <Box style={{display: isSmall ? 'grid' : 'flex'}} alignItems={"top"}>
                <Box flex={1} mr={"1em"}>
                    <Box flex={1}>
                        <MyTranslatableInputs
                            prompt={promptData.find(x => x.field === 'name')}
                            fullWidth
                            source={'name'}
                            label={translate('Name')}
                        />
                    </Box>
                </Box>
                <Box flex={1} display={'flex'}>
                    <Box style={{width: '100%'}}>
                        <MyTranslatableInputs
                            //prompt={promptData.find(x => x.field === 'url_repr')}
                            fullWidth
                            source={'url_repr'}
                            label={translate('URL Repr')}
                            slug={'name'}
                        />
                    </Box>
                    {id &&
                        <Box>
                            <FormDataConsumer>
                                {({formData, ...rest}) => {
                                    let repr = formData.url_repr.en ? formData.url_repr.en : ''
                                    return (
                                        <Link
                                            target={"_blank"}
                                            href={`${localStorage.getItem('site_domain')}/ch-en/${repr}`}
                                        >
                                            <LaunchIcon style={{marginTop: 16, marginLeft: 8}}/>
                                        </Link>
                                    )
                                }}
                            </FormDataConsumer>
                        </Box>
                    }

                </Box>
            </Box>

            <SectionNameInEdit name={'Display Options'} mt={16}/>

            <Box display="flex" alignItems={"top"}>
                <Box flex={1} mr={"1em"}>
                    <BooleanInput
                      source={'hidden_for_user'}
                      label={<Tooltip title={translate('hidden_for_user_description')}>
                          <Box>{translate('Hide category and its products')}</Box>
                      </Tooltip>}
                      defaultValue={false}
                      fullWidth/>
                </Box>

                <Box flex={1} mr={"1em"}>
                </Box>
                {!isSmall &&
                    <>
                        <Box flex={1} mr={"1em"}>
                        </Box>
                        <Box flex={1} alignItems={"top"}>
                        </Box>
                    </>
                }
            </Box>

            <Box display="flex" alignItems={"top"}>
                <Box flex={1} mr={"1em"}>
                    <BooleanInput
                      source={'show_on_main'}
                      label={<Tooltip title={translate('show_on_main_description')}>
                          <Box>{translate('Display On Main Page')}</Box>
                      </Tooltip>}
                      defaultValue={false}
                      fullWidth/>
                </Box>
                <Box flex={1} mr={"1em"}>
                    <NumberInput source={'column_on_main'} label={'Column on main'} variant={"outlined"}
                                 fullWidth/>
                </Box>
                {!isSmall &&
                    <>
                        <Box flex={1} mr={"1em"}>
                        </Box>
                        <Box flex={1} alignItems={"top"}>
                        </Box>
                    </>
                }
            </Box>

            <Box display="flex" alignItems={"top"}>
                <Box flex={1} mr={"1em"}>
                        <Box>
                            <BooleanInput
                              source={'show_on_menu'}
                              label={<Tooltip title={translate('show_on_menu_description')}>
                                         <Box>{translate('Display in Header and/or Catalogue tree')}</Box>
                                    </Tooltip>}
                              defaultValue={false}
                              fullWidth/>
                        </Box>

                </Box>
                <Box flex={1} mr={"1em"}>
                    <NumberInput source={'index'} label={'Index'} variant={"outlined"}
                                 fullWidth/>
                </Box>
                {!isSmall &&
                    <>
                        <Box flex={1} mr={"1em"}>
                        </Box>
                        <Box flex={1} alignItems={"top"}>
                        </Box>
                    </>
                }
            </Box>

            <Box display="flex" alignItems={"top"}>
                <Box flex={1} mr={"1em"}>
                    <BooleanInput
                      source={'show_on_filter'}
                      label={<Tooltip title={translate('show_on_filter_description')}>
                          <Box>{translate('Use as Filter in listing')}</Box>
                      </Tooltip>}
                      defaultValue={false}
                      fullWidth/>
                </Box>
                <Box flex={1} mr={"1em"}>
                    <NumberInput source={'order_on_filter'} label={'Order on filter'} variant={"outlined"}
                                 fullWidth/>
                </Box>
                {!isSmall &&
                    <>
                        <Box flex={1} mr={"1em"}>
                        </Box>
                        <Box flex={1} alignItems={"top"}>
                        </Box>
                    </>
                }
            </Box>
        </Box>
    )
}

const BlockEdit2 = props => {
    const translate = useTranslate();
    // const locale = useLocale();
    const {promptData, isSmall} = props
    // const formEdit = useForm();

    return (
        <Box p="1em" fullWidth>
            <Box style={{display: isSmall ? 'grid' : 'flex'}} alignItems={"top"}>
                <Box flex={1} mr={"1em"}>
                    <MyTranslatableInputs
                        prompt={promptData.find(x => x.field === 'title')}
                        fullWidth
                        source={'title'}
                        label={translate('Title')}
                    />
                </Box>
                <Box flex={1}>
                    <MyTranslatableInputs
                        prompt={promptData.find(x => x.field === 'breadcrumbs_name')}
                        fullWidth
                        source={'breadcrumbs_name'}
                        label={translate('Breadcrumbs Name')}
                    />
                </Box>
            </Box>

            <Box display="flex" alignItems={"top"} mt={'-4em'}>
                <Box flex={1}>
                    <MyTranslatableInputsRich
                        prompt={promptData.find(x => x.field === 'description')}
                        fullWidth
                        source={'description'}
                        label={translate('Short Description')}
                    />
                </Box>
            </Box>
            <HeaderRelationTable
                name={translate('Short Description')}
                par={'" ": It\'s crucial to provide a detailed description of the product category so users understand exactly what products are on the page. This is also important for indexing.'}
            />

            <Box display="flex" alignItems={"top"} mt={'-2em'}>
                <Box flex={1}>
                    <MyTranslatableInputsRich
                        prompt={promptData.find(x => x.field === 'HTML')}
                        fullWidth
                        source={'HTML'}
                        label={translate('Rich Description')}
                    />
                </Box>
            </Box>
            <HeaderRelationTable
                name={translate('Rich Description')}
                par={'" ": It\'s crucial to provide a detailed description of the product category so users understand exactly what products are on the page. This is also important for indexing.'}
            />

            <SectionNameInEdit name={'Banners'} mt={16}/>

            <Box mb="1em" flex={1} display="grid" alignItems={"top"}>
                <Box flex={1} alignItems={"top"} mt={2}
                     style={{border: '1px solid #ddd', borderRadius: 16}}>
                    <ImageInputComp
                        maxSize={500000}
                        heightImg={180}
                        source={'banner_desktop'}
                        // label={`${'New'} ${'image'}`}
                        label={'Desktop'}
                        accept="image/*"
                        //validate={required()}
                        defaultValue={''}
                        placeholder={<p>Drop your <b>Banner Desktop</b> here (500kb max)</p>}
                    />
                </Box>
                <Box flex={1} alignItems={"top"} mt={2}
                     style={{border: '1px solid #ddd', borderRadius: 16}}>
                    <ImageInputComp
                        maxSize={500000}
                        heightImg={180}
                        source={'banner_handset'}
                        // label={`${'New'} ${'image'}`}
                        label={'Mobile'}
                        accept="image/*"
                        //validate={required()}
                        defaultValue={''}
                        placeholder={<p>Drop your <b>Banner Mobile</b> (500kb max)</p>}
                    />
                </Box>
                <Box flex={1} alignItems={"top"} mt={2}
                     style={{border: '1px solid #ddd', borderRadius: 16}}>
                    <ImageInputComp
                        maxSize={500000}
                        heightImg={180}
                        source={'banner_tablet'}
                        // label={`${'New'} ${'image'}`}
                        label={'Tablet'}
                        accept="image/*"
                        //validate={required()}
                        defaultValue={''}
                        placeholder={<p>Drop your <b>Banner Tablet</b> here (500kb max)</p>}
                    />
                </Box>

            </Box>
        </Box>
    )
}

export const ProductsGroupEditCreate = props => {//Не работает
    /*const configureQuill = quill => quill.getModule('toolbar').addHandler('image', function (value) {
        this.quill.format('image', value)
    });*/


    const translate = useTranslate();
    const isSmall = useMediaQuery(function (theme) {
        return theme.breakpoints.down('sm');
    });
    const {record, resource} = props;
    const {id, name, url_repr} = record || {};
    const [promptData, setPromptData] = useState([]);

    const {data: dataPrompts, loading: isLoading} = useGetList(
        "dPrompts",
        {page: 1, perPage: 100},
        {field: 'id', order: 'ASC'},
    );

    useEffect(() => {
        if (dataPrompts) {
            let dt = getPromptForTable(dataPrompts, resource)
            setPromptData(dt)
            //console.log(resource, 'dt=============>',dt)
        }
    }, [dataPrompts, isLoading]);// eslint-disable-line

    // document.title = `iAM-Trade : Categories : ${id ? name.en : ''}`
    return (
        <Box>
            <Title title={`Categories : ${id ? name.en : ''}`} preferenceKey="title my"/>
            <TabbedForm
                {...props}
                 sanitizeEmptyValues={false}
                toolbar={<PostEditDeleteActions goBack={false}/>}
                //redirect={redirect_url}
            >
                <FormTab label={translate('Display Options')}>
                    {id &&
                        <Box display={'flex'} fullWidth>
                            <Box flex={1}>
                                <TranslateProduct
                                    isStatus={true}
                                    style={{float: 'right'}}
                                    type={'ProductsGroup'}
                                    name={'Category'}
                                    label={'Translate + SEO'}
                                    icon={<MyTranslateIcons/>}
                                    id={id}
                                />
                            </Box>
                            <Box flex={1} textAlign={'right'}>
                                <Link
                                    target={"_blank"}
                                    href={`${localStorage.getItem('site_domain')}/ch-en/${url_repr.en}`}
                                >
                                    <span>{`${localStorage.getItem('site_domain')}/ch-en/${url_repr.en}`}</span>
                                </Link>
                            </Box>
                        </Box>
                    }

                    <BlockEdit1
                        {...props}
                        url_repr={url_repr}
                        id={id}
                        promptData={promptData}
                        isSmall={isSmall}
                    />
                </FormTab>
                <FormTab label={translate('Static Page')}>
                    <HeaderRelationTable
                        par={'It\'s crucial to provide a detailed description of the product category so users understand exactly what products are on the page. This is also important for indexing.'}
                    />
                    <BlockEdit2
                        {...props}
                        promptData={promptData}
                        isSmall={isSmall}
                    />
                </FormTab>
                <FormTab label={translate('Main Banner')}>
                    <HeaderRelationTable
                        par={'Ensure your product category banner looks captivating on the site ' +
                            'by providing specific banners for different displays. ' +
                            'Fill in the details below to customize how your banners ' +
                            'appear across various devices, ensuring a seamless and ' +
                            'visually appealing experience for all users.'}
                    />
                    <BlockEditImg
                        {...props}
                        related_id={id}
                        //promptData={promptData}
                    />
                </FormTab>
                {id &&
                    <FormTab label={translate('Seo')}>
                        <Seo
                            // !!!! dProducts !!!!
                            {...props}
                            resource={'dProductsGroup'}
                            id={id}
                            promptData={promptData}
                        />
                    </FormTab>
                }
            </TabbedForm>

            {id &&
                <Box p="2em">
                    <h3>{translate('Related table')}</h3>
                    <BaseTab {...props} related_id={id}/>
                </Box>
            }

            {id && <FootherEdit source={record.source}/>}

        </Box>
    )
};

export default ProductsGroupEditCreate
