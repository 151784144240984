import * as React from 'react';
import {
    FormDataConsumer,
    TranslatableInputs, useTranslatableContext,
    //useTranslatableContext,
    useTranslate,
} from 'react-admin';
import RichTextInput from "ra-input-rich-text";

import {localesConfig} from "../utils/config";
import {useForm} from "react-final-form";
import get from 'lodash/get';


import {MyTranslateIcons} from "../icons/MyTranslateIcons";

import {StyledIconButton} from "./MyTranslatableInputs";
import {toolbarRichTextInput} from "../_constants/choices";
import {LinearProgress} from "@mui/material";
import {useEffect} from "react";
import {MyAiIcons} from "../icons/MyAiIcons";
import {getPrompt, getTranslate} from "./CompForApi/utilsAPI";
import {exchangeImage} from "./Transform";
// import TranslateIcon from '@material-ui/Translate';
// import GTranslateIcon from '@material-ui/GTranslate';


export const MyTranslatableInputsRich = props => {
    return (
        <FormDataConsumer>
            {({formData, ...rest}) => {
                return (
                    <Block
                        {...props}
                        formData={formData}
                    />
                )
            }}
        </FormDataConsumer>
    )
}

const BlockRichTextInput = props => {
    const {fullWidth, source, label, setCurrentLocale} = props
    const {
        // locales,
        // selectLocale,
        selectedLocale,
    } = useTranslatableContext();

    useEffect(() => {
        if (selectedLocale) {
            // console.log('selectedLocale=========>',selectedLocale)
            setCurrentLocale(selectedLocale)
        }
    }, [selectedLocale]);// eslint-disable-line

    return (
        <RichTextInput
            variant={'outlined'}
            label={label}
            //configureQuill={configureQuill}
            toolbar={toolbarRichTextInput}
            source={source}
            //validate={required()}
            fullWidth={fullWidth}
        />
    )
}
const Block = props => {
    const {
        formData,
        source,
        label,
        fullWidth = false,
        isTranslate = true,
        prompt = null
    } = props

    // console.log('PROPS=========>',props)
    const translate = useTranslate();
    //const locale = useLocale();
    const formEdit = useForm();

    const [progress, setProgress] = React.useState(false);
    const [flag, setFlag] = React.useState(1);
    // const currentLocale = localesConfig.defaultLocale;
    const [currentLocale, setCurrentLocale] = React.useState(localesConfig.defaultLocale);

    useEffect(() => {
        let tmp = {}
        //formEdit.change(source, null)
        if (formData.id) {
            tmp = get(formData, source, {})
            let newFormData = {}
            localesConfig.locales.forEach(it => {
                newFormData[it] = tmp[it] || undefined
            })

            formEdit.change(source, newFormData)
        } else {
            tmp = {}
            localesConfig.locales.forEach(it => {
                tmp[it] = ''
            })
            formEdit.change(source, tmp)
        }

    }, []);// eslint-disable-line

    useEffect(() => {
        let tmp = get(formData, source, null)
        // console.log(source, 'formData[source]==========>', tmp)
        if (tmp) {
            localesConfig.locales.forEach((lang, ind) => {
                exchangeImage(tmp[lang], lang).then(dt => {
                    if (dt) {
                        console.log(lang, 'TransformBase64========>', dt)
                        formEdit.change(`${source}.${dt.lang}`, dt.clearSt)
                    }
                })
            })
        }
    }, [formData[source]]);// eslint-disable-line

    const handleClickTranslate = async () => {
        let def = get(formData, source, null)
        if (def && def[localesConfig.defaultLocale]) {
            setProgress(true)
            let arrLocale = [currentLocale]
            if (currentLocale === localesConfig.defaultLocale) arrLocale = localesConfig.locales

            let res = await getTranslate(def[localesConfig.defaultLocale], arrLocale)

            setProgress(false)
            //console.log('RES=======>', res);
            if (res) {
                localesConfig.locales.forEach(lang => {
                    if (lang !== localesConfig.defaultLocale) {
                        let it = get(res, `${lang}.name`, '')
                        // console.log(lang, 'IT=======>', it);
                        if (it) {
                            def[lang] = it
                        }
                    }
                })
                formEdit.change(source, def)
            }
        }
        formEdit.change('flagCountTranslate', flag + 1)
        setFlag(flag + 1)
    }

    const handleClickPrompt = async () => {
        let def = get(formData, source, null)
        setProgress(true)
        //JSON.stringify(formData)
        let res = await getPrompt(prompt, formData)
        setProgress(false)
        if (res) {
            localesConfig.locales.forEach(lang => {
                if (lang === localesConfig.defaultLocale) {
                    def[lang] = res
                } else {
                    def[lang] = '';
                }
            })
            formEdit.change(source, def)
        }
        formEdit.change('flagCountTranslate', flag + 1)
        setFlag(flag + 1)
    }

    return (
        <div>
            {isTranslate
                ?
                <StyledIconButton
                    sx={{float: 'right', top: '38px', right: '16px', zIndex: 1}}
                    aria-label="toggle password visibility"
                    onClick={handleClickTranslate}
                    edge="end"
                >
                    <MyTranslateIcons/>
                </StyledIconButton>
                : null
            }
            {prompt && currentLocale === localesConfig.defaultLocale
                ?
                <StyledIconButton
                    sx={{float: 'right', top: '38px', right: '24px', zIndex: 1}}
                    aria-label="toggle password visibility"
                    onClick={handleClickPrompt}
                    edge="end"
                >
                    <MyAiIcons/>
                </StyledIconButton>
                : null
            }
            <TranslatableInputs
                //selector={<Selector />}
                locales={localesConfig.locales}
                defaultLocale={localesConfig.defaultLocale}
            >
                <BlockRichTextInput
                    setCurrentLocale={setCurrentLocale}
                    label={translate(label)}
                    //configureQuill={configureQuill}
                    source={source}
                    //validate={required()}
                    fullWidth={fullWidth}
                />
            </TranslatableInputs>
            {progress && <LinearProgress color="secondary"/>}
        </div>
    )
}
