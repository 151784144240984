import React, {useRef, useState, useEffect, useMemo} from 'react';
import {useMultiDrag, useMultiDrop} from 'react-dnd-multi-backend';
import {Box, MenuList, Typography, MenuItem, Stack, IconButton, Popover} from '@mui/material';
import {get, isEmpty, isFunction, join, map} from 'lodash';
import {TreeItem} from '@mui/x-tree-view/TreeItem';
import {RecordContextProvider, useLocale, useTranslate} from 'react-admin';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import useCategoriesTreeActions from '../../../../_hooks/useCategoriesTreeActions';
import useGetContext from '../../../../_hooks/useGetContext';
import {CategoriesTreeContext} from '../../../_providers/CategoriesTreeProvider';
import {Link} from 'react-router-dom';
import PropTypes from 'prop-types';
import TranslatePattern from '../../../TranslatePattern';
import SelectAndSaveChoices from '../../../SelectAndSaveChoices';
import useCategoryTypes from '../../../../_hooks/useCategoryTypes';
import {CATEGORIES_TYPES} from '../../../../utils/constants';
import SwitchDisplayType from '../SwitchDisplayType';

function CategoryDndItem({
   dragItem,
   parentPath = [],
   handleDrop,
   parentCategoryItem,
}) {
  const translate = useTranslate();
  const locale = useLocale();

  const {typesIcons} = useCategoryTypes();

  const {flattenTree, refetchTree} = useGetContext(CategoriesTreeContext)

  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorElSettings, setAnchorElSettings] = useState(null);

  const popperRef = useRef(null);

  const [didDropItem, setDidDropItem] = useState(null);

  const {
    handleRemoveAlternateParent,
    handleUpdateRelations,
    removeDirectParent,
    replaceDirectToAlternateParent,
  } = useCategoriesTreeActions();


  const [[{ isDragging }, dragRef]] = useMultiDrag({
    type: CATEGORIES_TYPES?.[dragItem?.hierarchy_type],
    item: {...dragItem, parentPath},
    collect: (monitor) => {
      return {
        isDragging: monitor.isDragging(),
      };
    },
  });


  const [[{ canDrop, isOver }, dropRef]] = useMultiDrop({
    accept: [CATEGORIES_TYPES?.MIXED, CATEGORIES_TYPES.GROUP_ONLY, CATEGORIES_TYPES.ITEM_ONLY],
    drop: (item, monitor) => {
      const isCanDrop = monitor.canDrop();
      const isOver = monitor.isOver();
      if (!isOver || !isCanDrop) return;

      setDidDropItem(item);

    },
    canDrop: (item, monitor) => {
      return dragItem?.id !== item?.id && CATEGORIES_TYPES?.[dragItem?.hierarchy_type] !== CATEGORIES_TYPES?.ITEM_ONLY
    },
    collect: (monitor) => {
      return {
        canDrop: monitor.canDrop(),
        isOver: monitor.isOver({ shallow: true }),
      };
    },
  });

  useEffect(() => {
    if (popperRef.current) {
      setAnchorEl(popperRef.current);
    }
  }, [popperRef]);

  const handleClosePopover = (event) => {
    event.stopPropagation();
    setDidDropItem(null);
  }

  const finallyDropItem = ({ isDirect, isAlternative }) => (event) => {
    event.stopPropagation();

    handleDrop(didDropItem, {...dragItem, parentPath}, {isDirect, isAlternative});
    setDidDropItem(null);
  }

  const handleOpenSettings = (event) => {
    event.stopPropagation();
    setAnchorElSettings(event.currentTarget);
  }

  const handleCloseSettings = (event) => {
    event.stopPropagation();
    setAnchorElSettings(null);
  }

  const directParentCategory = useMemo(() => {
    const directItem = flattenTree?.[dragItem?.product_group_parent_id];

    if (!directItem) return;

    return directItem;

  }, [dragItem, flattenTree])

  const handleReplaceDirectToAlternate = (event) => {
    event.stopPropagation();

    if (isEmpty(parentCategoryItem) || isEmpty(directParentCategory))

     handleUpdateRelations(
      dragItem, parentCategoryItem, true
    )

     handleUpdateRelations(
      dragItem, directParentCategory, false
    )

    if (isFunction(refetchTree)) {
      refetchTree()
    }
    setAnchorElSettings(null);
  }

  const removeAlternateParent = (event) => {
    event.stopPropagation();

    handleRemoveAlternateParent({...dragItem, parentPath}, parentCategoryItem);

    if (isFunction(refetchTree)) {
      refetchTree()
    }

    setAnchorElSettings(null);
  }

  const updateRelations = (event) => {
    event.stopPropagation();

    handleUpdateRelations(dragItem, parentCategoryItem, true)

    if (isFunction(refetchTree)) {
      refetchTree()
    }

    setAnchorElSettings(null);
  }

  const handleRemoveDirectParent = (event) => {
    event.stopPropagation();

    removeDirectParent(dragItem);

    if (isFunction(refetchTree)) {
      refetchTree()
    }

    setAnchorElSettings(null);
  }

  const handleReplaceDirectToAlternateParent = (event) => {
    event.stopPropagation();

    replaceDirectToAlternateParent(dragItem, parentCategoryItem);

    if (isFunction(refetchTree)) {
      refetchTree()
    }

    setAnchorElSettings(null);
  }


  const handleCallbackChangeStatus = () => {
    if (isFunction(refetchTree)) {
      refetchTree()
    }
  }

  const recordStatuses = useMemo(() => {
    return {
      id: dragItem?.product_group_id,
      show_on_filter: dragItem?.show_on_filter,
      show_on_main: dragItem?.show_on_main,
      show_on_menu: dragItem?.show_on_menu,
      hidden_for_user: dragItem?.hidden_for_user,
      show_type: dragItem?.show_type
    }
  }, [dragItem])

  const controlsTypeShowCategory = useMemo(() => {
    return [
      {value: 'SHOW_CATEGORY',
        content: <Box sx={{
          width: 15,
          height: 15,
          borderRadius: '50%',
          background: '#a4e87d'
        }
        }/>
      },
      {value: 'HIDE_CATEGORY_SHOW_CHILDREN',
        content: <Box sx={{
          width: 15,
          height: 15,
          borderRadius: '50%',
          background: 'linear-gradient(to right, #a4e87d 50%, #e88b7d 50%)'
        }
        }/>
      },
      {value: 'HIDE_CATEGORY_AND_CHILDREN',
        content: <Box sx={{
          width: 15,
          height: 15,
          borderRadius: '50%',
          background: '#e88b7d'
        }
        }/>
      }
    ]
  }, [])



  const labelCategory = useMemo(() => {

    const categoryName = get(dragItem, `name_${locale}`) || dragItem?.name
    const parentCategoryName = get(parentCategoryItem, `name_${locale}`) || parentCategoryItem?.name
    const directCategoryName = get(directParentCategory, `name_${locale}`) || directParentCategory?.name

    return (
      <Box>
        <Box
          sx={{ opacity: isDragging ? 0.8 : 1, cursor: 'grab', backgroundColor: isOver && canDrop ? '#ddd' : 'inherit' }}
          ref={dragRef}>
          <Stack direction='row' alignItems='center' spacing={2} justifyContent='space-between'>

          <Stack direction='row' flex={1} alignItems='center' spacing={0}>
            <Typography variant='subtitle1' sx={{wordBreak: 'break-all', paddingRight: 1}} color={dragItem?.alternate ? 'primary' : 'inherit'}>
              {categoryName}
            </Typography>

          </Stack>

            <Stack direction='row' flex={1} justifyContent='flex-end' alignItems='center' sx={{minWidth: 130}}>
              <RecordContextProvider value={recordStatuses}>
                <SelectAndSaveChoices
                  source="hidden_for_user"
                  callbackUpdate={handleCallbackChangeStatus}
                  hasInvertValue
                  statuses={[]}
                  hasPopover={false}
                  send_only_this_field
                  resource={'dProductsGroup'}
                  showTextValue={true}
                  width={'2em'}
                  edit={true}
                  circle={'not inv'}
                />

                <SelectAndSaveChoices
                  source="show_on_main"
                  callbackUpdate={handleCallbackChangeStatus}
                  statuses={[]}
                  hasPopover={false}
                  send_only_this_field
                  resource={'dProductsGroup'}
                  showTextValue={true}
                  width={'2em'}
                  edit={true}
                  circle={'not inv'}
                />

                <SwitchDisplayType
                 controls={controlsTypeShowCategory}
                 callbackUpdate={handleCallbackChangeStatus}
                 resource='dProductsGroup'
                 source='show_type'
                />

                <SelectAndSaveChoices
                  source="show_on_filter"
                  callbackUpdate={handleCallbackChangeStatus}
                  statuses={[]}
                  hasPopover={false}
                  send_only_this_field
                  resource={'dProductsGroup'}
                  showTextValue={true}
                  width={'2em'}
                  edit={true}
                  circle={'not inv'}
                />
              </RecordContextProvider>


              <IconButton onClick={handleOpenSettings}>
                <MoreVertIcon/>
              </IconButton>

              {/*{!dragItem?.show_on_menu*/}
              {/*  ? <Typography variant='body2' sx={{color: (theme) => theme.palette.text.secondary}}>{translate('hidden_for_user_category')}</Typography>*/}
              {/*  : null}*/}

              <Popover
                open={Boolean(anchorElSettings)}
                anchorEl={anchorElSettings}
                onClose={handleCloseSettings}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
              >
                <MenuList>
                  {dragItem?.alternate && !isEmpty(parentCategoryItem)
                    ? <MenuItem
                      onClick={removeAlternateParent}>
                      <TranslatePattern translateKey='remove_alternate_parent' options={{parent_name: (<b>{parentCategoryName}</b>)}}/>
                    </MenuItem>
                    : null}


                  {dragItem?.alternate
                  && !isEmpty(parentCategoryItem)
                  && !isEmpty(directParentCategory)
                    ? <React.Fragment>
                      <MenuItem onClick={handleReplaceDirectToAlternate}>
                        <TranslatePattern translateKey='replace_alternate_to_parent' options={
                          {
                            parent_name: (<b>{parentCategoryName}</b>),
                            alternate_parent_name: (<b>{directCategoryName}</b>)
                          }
                        }/>
                      </MenuItem>

                      <MenuItem onClick={updateRelations}>
                        <TranslatePattern translateKey='replace_parent' options={
                          {
                            parent_name: (<b>{parentCategoryName}</b>),
                            alternate_parent_name: (<b>{directCategoryName}</b>)
                          }
                        }/>
                      </MenuItem>
                    </React.Fragment>
                    : null}

                  {
                    !dragItem?.alternate && !isEmpty(parentCategoryItem)
                      ? <MenuItem onClick={handleRemoveDirectParent}>
                        <TranslatePattern translateKey='remove_parent' options={
                          {
                            parent_name: (<b>{parentCategoryName}</b>)
                          }
                        }/>
                      </MenuItem>
                      : null
                  }

                  {
                    !dragItem?.alternate && !isEmpty(parentCategoryItem)
                      ? <MenuItem onClick={handleReplaceDirectToAlternateParent}>
                        <TranslatePattern translateKey='make_alternative' options={
                          {
                            parent_name: (<b>{parentCategoryName}</b>)
                          }
                        }/>
                      </MenuItem>
                      : null
                  }

                  <MenuItem
                    component={Link}
                    target='_blank'
                    to={`/dProductsGroup/${dragItem?.product_group_id}`}>
                    {translate('edit_category')}
                  </MenuItem>


                  <MenuItem onClick={handleCloseSettings}>{translate('undo_action')}</MenuItem>
                </MenuList>
              </Popover>
            </Stack>


          </Stack>
        </Box>

        <Box sx={{position: 'relative'}} ref={popperRef}>
          {anchorEl ? (
            <Popover
              open={Boolean(didDropItem)}
              anchorEl={anchorEl}
              onClose={handleClosePopover}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
            >
              <MenuList>
                <MenuItem
                  onClick={finallyDropItem(
                    {isDirect: true, isAlternative: false})
                  }>
                  <TranslatePattern translateKey='make_direct' options={
                    {
                      parent_name: (<b>{categoryName}</b>)
                    }
                  }/>
                </MenuItem>

                <MenuItem onClick={finallyDropItem(
                  {isDirect: false, isAlternative: true})
                }>
                  <TranslatePattern translateKey='make_alternative' options={
                    {
                      parent_name: (<b>{categoryName}</b>)
                    }
                  }/>
                </MenuItem>

                <MenuItem onClick={handleClosePopover}>{translate('undo_action')}</MenuItem>
              </MenuList>
            </Popover>
          ) : null}
        </Box>
      </Box>
    )
  }, [
    dragItem,
    finallyDropItem,
    popperRef,
    anchorEl,
    parentCategoryItem,
    directParentCategory,
    didDropItem,
    anchorElSettings,
    isDragging,
    isOver,
    canDrop,
    locale
  ])

  return (
    <TreeItem
      icon={typesIcons?.[CATEGORIES_TYPES?.[dragItem?.hierarchy_type]]}
      ref={dropRef}
      sx={{
        '&& .MuiTreeItem-iconContainer svg': {
          fontSize: 22
        }
      }}
      onFocusCapture={e => e.stopPropagation()}
      label={labelCategory}
      nodeId={join(parentPath, '-')}>

      {!isEmpty(dragItem?.sub_items) ? map(dragItem?.sub_items, (childItem, key) => (
        <CategoryDndItem
          parentCategoryItem={dragItem}
          handleDrop={handleDrop}
          key={key}
          dragItem={childItem}
          parentPath={[...parentPath, 'sub_items', key]} />
      )) : null}
    </TreeItem>
  )
}

CategoryDndItem.propTypes = {
  dragItem: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    name: PropTypes.string.isRequired,
    alternate: PropTypes.bool,
    product_group_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    product_group_parent_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    sub_items: PropTypes.arrayOf(PropTypes.object),
    show_on_filter: PropTypes.bool,
    show_on_main: PropTypes.bool,
    show_on_menu: PropTypes.bool,
    hidden_for_user: PropTypes.bool,
    hierarchy_type: PropTypes.oneOf(['MIXED', 'GROUP_ONLY', 'ITEM_ONLY']),
    show_type: PropTypes.oneOf(['SHOW_CATEGORY', 'HIDE_CATEGORY_SHOW_CHILDREN', 'HIDE_CATEGORY_AND_CHILDREN'])
  }).isRequired,

  parentPath: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  ),

  handleDrop: PropTypes.func,

  parentCategoryItem: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    name: PropTypes.string,
    alternate: PropTypes.bool,
    sub_items: PropTypes.arrayOf(PropTypes.object),
    parentPath: PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    ),
    show_on_filter: PropTypes.bool,
    show_on_main: PropTypes.bool,
    show_on_menu: PropTypes.bool,
    hidden_for_user: PropTypes.bool,
  }),
};

export default React.memo(CategoryDndItem)
