import React, {useMemo} from 'react';
import {useRecordContext, useUpdate} from 'react-admin';
import get from 'lodash/get';
import {Box} from '@mui/material';
import {find, findIndex, isFunction, size} from 'lodash';

export default function SwitchDisplayType({callbackUpdate, source, controls, resource}) {

  const [update, {loading, error}] = useUpdate();

  const record = useRecordContext();

  const fieldValue = useMemo(() => {
    return get(record, source, null)
  }, [record, source])

  const currentContent = useMemo(() => {
    return find(controls, (item) => item?.value === fieldValue)
  }, [fieldValue, controls])


  const onClick = (event) => {
    event.stopPropagation();
    event.preventDefault();

    if (!record?.id) return;

    const indexCurrentItem = findIndex(controls, (item) => item?.value === fieldValue)
    if (indexCurrentItem === undefined) return;

    const newIndex = size(controls) >= indexCurrentItem + 2 ? indexCurrentItem + 1 : 0

    const newOption = controls[newIndex];

    const data = {id: record?.id}

    data[source] = newOption?.value
    update(resource, record?.id, data, record);

    if (isFunction(callbackUpdate)) callbackUpdate()
  }

  return <Box onClick={onClick} p={1} sx={{cursor: 'pointer'}}>
    {currentContent ? currentContent?.content : null}
  </Box>

}