import * as React from 'react';

import {
    BooleanField,
    DateField, NumberField, useRecordContext,
    DateTimeInput,
    DateInput,
    NumberInput, useGetOne, useTranslate, useGetList, ImageInput, FormDataConsumer
} from 'react-admin';

import BooleanOnIcon from "@material-ui/icons/CheckCircleOutline";
import BooleanOffIcon from "@material-ui/icons/Close";
import {Avatar, Box, Button} from "@material-ui/core";
// import AddBoxIcon from '@material-ui/icons/AddToPhotosOutlined';
import CopyClipBoardIcon from "../icons/CopyClipBoardIcon";

import {useEffect, useMemo, useRef} from 'react';
//AddBox

import AccessTimeIcon from '@material-ui/icons/AccessTime';
import {FilterList, FilterListItem, EditButton, useRedirect, useDelete} from 'react-admin';
import {
    //endOfYesterday,
    startOfWeek,
    //subWeeks,
    //addWeeks,
    startOfMonth,
    subMonths,
    subYears,
    startOfDay,
    //endOfDay,
    addDays,
    //addMonths,
    addYears,
    endOfWeek,
    endOfMonth,
} from 'date-fns';
import get from "lodash/get";
import set from "lodash/set";
import moment from "moment";
import useMyStyles from "../utils/useMyStyles";
import {ListItem, ListItemAvatar, ListItemText} from "@mui/material";
import {useForm} from "react-final-form";
import {isAbsoluteUrl} from '../utils/isAbsoluteUrl';

export const FilterDataAside2 = (props) => {
    const {fields, name} = props;
    return (
        <FilterList
            label={name}
            icon={<AccessTimeIcon/>}
        >
            <FilterListItem
                label="Tomorrow"
                value={{
                    // [fields + '_gte']: startOfDay(new Date()).toISOString(), //endOfYesterday().toISOString(),
                    [fields + '_gte']: startOfDay(addDays(new Date(), 1)).toISOString(),
                    [fields + '_lte']: startOfDay(addDays(new Date(), 2)).toISOString(),
                }}
            />
            <FilterListItem
                label="Today"
                value={{
                    [fields + '_gte']: startOfDay(new Date()).toISOString(), //endOfYesterday().toISOString(),
                    [fields + '_lte']: startOfDay(addDays(new Date(), 1)).toISOString(),
                }}
            />
            {/*<FilterListItem
                label="Today"
                value={{
                    [fields + '_gte']: startOfDay(new Date()).toISOString(), //endOfYesterday().toISOString(),
                    [fields + '_lte']: endOfWeek(new Date()).toISOString(),
                }}
            />*/}
            <FilterListItem
                label="This week"
                value={{
                    [fields + '_gte']: moment(startOfWeek(new Date())).format('YYYY-MM-DD'),//startOfDay(new Date()).toISOString(),
                    [fields + '_lte']: moment(endOfWeek(new Date())).format('YYYY-MM-DD'),//endOfWeek(new Date()).toISOString(),
                }}
            />
            {/*<FilterListItem
                label="Next week"
                value={{
                    [fields + '_gte']: moment(addWeeks(startOfWeek(new Date()), 1)).format('YYYY-MM-DD'),//addWeeks(startOfWeek(new Date()), 1).toISOString(),
                    [fields + '_lte']: moment(addWeeks(endOfWeek(new Date()), 1)).format('YYYY-MM-DD'),//addWeeks(endOfWeek(new Date()), 1).toISOString(),
                }}
            />*/}
            <FilterListItem
                label="This month"
                value={{
                    [fields + '_gte']: startOfMonth(new Date()).toISOString(),
                    // target_date_lte: startOfDay(addDays(new Date(), 1)).toISOString(),
                    [fields + '_lte']: endOfMonth(new Date()).toISOString(),
                }}
            />
            <FilterListItem
                label="Custom"
                value={{
                    [fields + '_gte']: subYears(
                        startOfMonth(new Date()),
                        1
                    ).toISOString(),

                    [fields + '_lte']: addYears(
                        startOfMonth(new Date()),
                        1
                    ).toISOString(),
                }}
            />
        </FilterList>
    )
}
export const FilterDataAside = (props) => {
    const {fields, name} = props;
    return (
        <FilterList
            label={name}
            icon={<AccessTimeIcon/>}
        >
            <FilterListItem
                label="Today"
                value={{
                    [fields + '_gte']: startOfDay(new Date()).toISOString(), //endOfYesterday().toISOString(),
                    [fields + '_lte']: startOfDay(addDays(new Date(), 1)).toISOString(),
                }}
            />
            <FilterListItem
                label="This week"
                value={{
                    [fields + '_gte']: startOfWeek(new Date()).toISOString(),
                    //target_date_lte: startOfDay(addDays(new Date(), 1)).toISOString(),//endOfDay(new Date()).toISOString(),
                    [fields + '_lte']: endOfWeek(new Date()).toISOString(),//endOfDay(new Date()).toISOString(),
                }}
            />
            {/*<FilterListItem
                label="Last week"
                value={{
                    [fields + '_gte']: subWeeks(
                        startOfWeek(new Date()),
                        1
                    ).toISOString(),
                    [fields + '_lte']: startOfWeek(new Date()).toISOString(),
                }}
            />*/}
            <FilterListItem
                label="This month"
                value={{
                    [fields + '_gte']: startOfMonth(new Date()).toISOString(),
                    // target_date_lte: startOfDay(addDays(new Date(), 1)).toISOString(),
                    [fields + '_lte']: endOfMonth(new Date()).toISOString(),
                }}
            />
            {/*<FilterListItem
                label="Last month"
                value={{
                    [fields + '_gte']: subMonths(
                        startOfMonth(new Date()),
                        1
                    ).toISOString(),
                    [fields + '_lte']: startOfMonth(new Date()).toISOString(),
                }}
            />*/}
            <FilterListItem
                label="Earlier"

                value={{
                    [fields + '_gte']: subYears(
                        startOfMonth(new Date()),
                        20
                    ).toISOString(),

                    [fields + '_lte']: subMonths(
                        startOfMonth(new Date()),
                        1
                    ).toISOString(),
                }}
            />
        </FilterList>
    )
}

export const DisabledField = (props) => {
    const classes = useMyStyles();
    const {idField, resourceField, field, text, type = 'text', label} = props;
    const translate = useTranslate();
    return (
        <div className={classes.cost_show_field}>
            <div>
                <span style={{fontSize: 12}}>{label}</span>
            </div>
            <div>
                {type === 'text'
                    ? <span style={{fontWeight: 'bold'}}>{translate(text)}</span>
                    : <GetFieldById id={idField} resource={resourceField} field={field}/>
                }

            </div>
        </div>
    );
}
export const GetFieldById = (props) => {
    const {id, resource, field, bold = true} = props;
    const translate = useTranslate();
    let title = "";
    const {data, loaded} = useGetOne(resource, id);
    if (loaded && data) title = get(data, field, null);

    return (
        <span style={{fontWeight: bold && 'bold'}}>
            {typeof title === "boolean"
                ? title ? field : ''
                : translate(title)
            }
        </span>
    );
}
export const MyCount = (props) => {
    const {resource, filter} = props;
    //console.log(resource,'MyCount====>', filter)
    const {total} = useGetList(resource,
        {page: 1, perPage: 1},
        {field: 'updated', order: 'DESC'},
        filter,
        //{},
    );
    return <span>{total}</span>
}
export const ImageInfo = (props) => {
    const {src='image', type = 'avatar', link=null} = props
    const record = useRecordContext();
    let imgName = get(record, src, null)

    const transformedSrc = useMemo(() => {
        if (!record[src]) return;

        if (!isAbsoluteUrl(record[src])) {
            return `${process.env.REACT_APP_API_URL}/files_media/${record[src]}`;
        }

        return record[src]

    }, [record])
    if (imgName || link)
        return (
            <Avatar
                variant={type !== 'avatar' && "rounded"}
                // variant={"rounded"}
                src={link ? `${link}?size=24x24` : `${transformedSrc}?size=24x24`}
                sx={{bgcolor: 'background.paper'}}
                alt={''}
            />
        )
    else return null
}

export const CustomerInfo = (props) => {
    const {phone = null, name, src, type = 'avatar', locale = null} = props
    const record = useRecordContext();
    // console.log('record=====>', record)
    return (
        <ListItem sx={{padding: 0}}/*button component={Link} to={`/commands/${order.id}`}*/>
            <ListItemAvatar>
                <Avatar
                    variant={type !== 'avatar' && "rounded"}
                    // variant={"rounded"}
                    src={`${record[src]}?size=24x24`}
                    sx={{bgcolor: 'background.paper'}}
                    alt={locale ? `${record[name][locale]}` : `${record[name]}`}
                />
            </ListItemAvatar>
            <ListItemText
                //primary={new Date(order.update).toLocaleString('en-GB')}
                primary={locale ? record[name][locale] : record[name]}
                secondary={phone ? record[phone] : ''}
            />
            {/*<ListItemSecondaryAction>
                <Box
                    component="span"
                    sx={{
                        marginRight: '1em',
                        color: 'text.primary',
                    }}
                >
                    {formatSpacerNumber(sell)}$
                </Box>
            </ListItemSecondaryAction>*/}
        </ListItem>
    )
}

export const UserInfo = (props) => {
    const {phone = true} = props
    const record = useRecordContext();
    // console.log('record=====>', record)
    return (
        <ListItem sx={{padding: 0}}/*button component={Link} to={`/commands/${order.id}`}*/>
            <ListItemAvatar>
                <Avatar
                    src={`${record?.avatar}?size=16x16`}
                    sx={{bgcolor: 'background.paper'}}
                    alt={`${record?.first_name} ${record?.last_name}`}
                />
            </ListItemAvatar>
            <ListItemText
                //primary={new Date(order.update).toLocaleString('en-GB')}
                primary={`${record?.first_name} ${record?.last_name}`}
                secondary={phone ? record.phone : ''}
            />
        </ListItem>
    )
}

export const DateInputComp = (props) => {
    const {time = false} = props;
    if (time)
        return (
            <DateTimeInput
                {...props}
                // source={source}
                // options={{ format: "MM/DD/YYYY", clearable: true }}
                locales="en-Us"
            />
        )
    else
        return (
            <DateInput

                {...props}
                // source={source}
                // options={{ format: "MM/DD/YYYY", clearable: true }}
                locales="en-Us"
            />
        )
}

export function getDt(dt, showTime=false){
     let tz = moment().utcOffset()
    //console.log('utcOffset()=======>', moment().utcOffset())
    // if (showTime) dt = dt ? moment(dt).format("D-MMM-YY, h:mm:ss\u00a0a") : '';
    if (showTime) dt = dt ? moment(dt).add(tz, 'minutes').format("D-MMM-YY, H:mm:ss") : '';
    else dt = dt ? moment(dt).add(tz, 'minutes').format("D-MMM-YY") : '';

    return dt
}
export const DateFieldComp = (props) => {
    /*
    moment().format('MMMM Do YYYY, h:mm:ss a'); // December 14th 2022, 2:47:57 pm
    moment().format('dddd');                    // Wednesday
    moment().format("MMM Do YY");               // Dec 14th 22
    moment().format('YYYY [escaped] YYYY');     // 2022 escaped 2022
    moment().format();
    */
    //console.log('DateFieldComp=========>', props)
    const {source, showTime = true, locale = 'en'} = props;
    let record = useRecordContext(props);
    let dt = get(record, source, '');

    let tz = moment().utcOffset()
    //console.log('utcOffset()=======>', moment().utcOffset())
    // if (showTime) dt = dt ? moment(dt).format("D-MMM-YY, h:mm:ss\u00a0a") : '';
    if (showTime) dt = dt ? moment(dt).add(tz, 'minutes').format("D-MMM-YY, H:mm:ss") : '';
    else dt = dt ? moment(dt).add(tz, 'minutes').format("D-MMM-YY") : '';
    if (locale === 'ru') {
        return (
            <DateField
                showTime={showTime}
                {...props}
            />
        )
    } else {
        return (
            <span>{dt}</span>
        )
        /*return (
            <DateField
                showTime={showTime}
                {...props}
                // source={source}
                locales="en-Us"
                // options={{ weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }}
                options={{year: 'numeric', month: 'short', day: 'numeric'}}
                //showTime={true}
            />
        )*/
    }
}
export const NumberInputFloatComp = (props) => {
    return (
        <NumberInput
            {...props}
            format={v => parseFloat(v)}
            // helperText={'Error Error'}
            //format={v => v * 100}
            //parse={v => parseFloat(v)}
        />
    )
}
export const NumberFieldComp = (props) => {
    const {bold} = props;
    return (
        <NumberField
            {...props}
            // source={source}
            locales="en-Us"
            options={{style: 'currency', currency: 'USD'}}
            style={{fontWeight: bold && 'bold'}}
        />
    )
}

function unsecuredCopyToClipboard(text) {
    const textArea = document.createElement("textarea");
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    try {
        document.execCommand('copy');
    } catch (err) {
        console.error('Unable to copy to clipboard', err);
    }
    document.body.removeChild(textArea);
}

const handleClickCopyClipBoard = (event, text, tooltipText) => {
    event.preventDefault();
    event.stopPropagation();
    tooltipText.current.style.visibility = "visible";
    setTimeout(function () {
        if (tooltipText.current) {
            tooltipText.current.style.visibility = "hidden";
        }
    }, 1500);

    if (window.isSecureContext && navigator.clipboard) {
        navigator.clipboard.writeText(text);
    } else {
        unsecuredCopyToClipboard(text);
    }
}

const handleClickCopyClipBoardId = (event, source, tooltipText) => {
    event.preventDefault();
    event.stopPropagation();
    tooltipText.current.style.visibility = "visible";
    setTimeout(function () {
        if (tooltipText.current) tooltipText.current.style.visibility = "hidden";
    }, 1500);
    let res = document.getElementById(source);
    if (res) window.navigator.clipboard.writeText(res.value)
    else window.navigator.clipboard.writeText('')
}

export const CopyClipBoard = (props) => {
    //const record = useRecordContext();
    const {source} = props;
    const tooltipText = useRef();
    return (
        <>
            <span
                ref={tooltipText}
                className="tooltipText"
                style={{marginLeft: '-128px', marginTop: '-18px'}}
            >
                Copied to clipboard
            </span>
            <CopyClipBoardIcon
                onClick={(event) => handleClickCopyClipBoardId(event, source, tooltipText)}
                style={{
                    // color: "#444!important",
                    // backgroundColor: 'green',
                    cursor: "pointer",
                    position: "absolute",
                    marginLeft: '-28px',
                    marginTop: '22px',
                }}
                title='write Text'
            />
        </>
    );
};

export const TextFieldComp = (props) => {
    const record = useRecordContext();
    const {source, itsOriText = false, copyClipBoard = false} = props;
    const text = record &&
    itsOriText
        ? itsOriText
        : record[source];

    const tooltipText = useRef();
    return (
        <>
            {copyClipBoard && <span ref={tooltipText} className="tooltipText">Copy to clipboard: {text}</span>}
            <span
                style={{margin: 16, padding: 16}}
                {...props}
            >
            {text}
        </span>
            {copyClipBoard &&
                <CopyClipBoardIcon
                    onClick={(event) => handleClickCopyClipBoard(event, text, tooltipText)}
                    style={{cursor: "pointer", position: "absolute", marginLeft: '2px'}}
                    title='write Text'
                />
            }
        </>
    );
};

export const MyEditButton = (props) => {
    const {path, fieldId} = props;
    const record = useRecordContext();
    const redirect = useRedirect()
    let id = get(record, fieldId, '')
    //console.log('passenger=======>', record)
    return (
        <EditButton label=" " onClick={() => redirect(`/${path}/${id}`)}/>
    )
}

export const MyDeleteButton = (props) => {
    const {resource, fieldId} = props;
    const record = useRecordContext();
    let id = get(record, fieldId, '')

    const [deleteOne, {loaded}] = useDelete(
        resource,
        id
    );
    const handleClick = () => {
        deleteOne(resource, id);
    }

    if (loaded) {
        console.log(id, 'error || isLoading=======>', loaded)
    }
    return (
        <Button label="DEL" onClick={handleClick}>DEL</Button>
    )
}

export const TextFieldButton = (props) => {
    const record = useRecordContext();
    const tooltipText = useRef();
    const {
        source, source2 = '', source3 = '',
        title = '', itsOriText = false, copyClipBoard = false,
        pasw = false, delim = '\u00a0',
        backgroundColor = null,//'#eee',
        if_field = null,
        if_value = null,
        bold = true,
    } = props;

    let tit = get(record, title, '');
    let arr = [];
    arr.push(get(record, source, ''));
    if (source2) arr.push(get(record, source2, ''));
    if (source3) arr.push(get(record, source3, ''));

    const text = record &&
    itsOriText
        ? itsOriText ? itsOriText.replaceAll(' ', '\u00a0') : ''
        : String(arr.join(delim).replaceAll(' ', '\u00a0'));

    let alarm = false;
    if (if_field && if_value) {
        alarm = get(record, if_field, false)
        if (alarm !== if_value) alarm = true; else alarm = false;
    }
    return (
        <>
            {text && copyClipBoard && <span ref={tooltipText} className="tooltipText">Copy to clipboard: {text}</span>}
            <span
                {...props}
                style={{
                    color: alarm && 'red',
                    fontWeight: bold && 'bold',
                    padding: copyClipBoard ? '8px 32px 8px 12px' : '8px 12px',
                    backgroundColor: backgroundColor,
                    borderRadius: '8px',
                    //marginLeft: -12,
                }}
                title={tit}
            >
            {pasw
                ? '****'
                : alarm ? `${text} - Not matching the selected Customer` : text}
        </span>
            {text && copyClipBoard &&
                <CopyClipBoardIcon
                    onClick={(event) => handleClickCopyClipBoard(event, text, tooltipText)}
                    style={{cursor: "pointer", position: "absolute", marginLeft: "-28px", color: 'gray'}}
                    title='write Text'
                />
            }
        </>
    );
};

export const TextFieldBold = ({source}) => {
    const record = useRecordContext();
    let pr = record ? get(record, source, '') : '';
    return (
        <span style={{fontWeight: 'bold'}}>
            {pr}
        </span>
    );
};

export const BooleanFieldComp = (props) => {
    // const {source} = props;
    return (
        <BooleanField
            style={{display: "grid"}}
            {...props}
            // source={source}
            valueLabelTrue={'YES'} valueLabelFalse={'NO'}
            TrueIcon={BooleanOnIcon} FalseIcon={BooleanOffIcon}
        />
    )
}
export const BooleanFieldCompAct = (props) => {
    const {source, inv = false} = props
    const record = useRecordContext();
    let pr = record ? get(record, source, '') : null;
    if(inv) pr = !pr
    return (
        <div style={{width: '100%', textAlign: '-webkit-center'}}>
        <Box
            title={pr ? 'Active' : 'Not Active'}
            style={{
                width: 15, height: 15,
                borderRadius: '50%',
                //border: '1px solid black',
                backgroundColor: pr !== null
                    ? pr ? '#a4e87d' : '#e88b7d'
                    : 'none'
        }}
        />
        </div>
    )
}


const getSrc = (record, source, itsOriSrc) => {
    let src = '';
    if (itsOriSrc === true) src = source;
    else {
        src = source.split('.');
        if (src.length > 1) {
            src = record[src[0]] && record[src[0]][src[1]] ? record[src[0]][src[1]] : '';
        } else src = record[src[0]];
        //src = `${record[source]}`;
    }
    return src
}
export const VideoFieldComp = (props) => {
    const record = useRecordContext(props);
    const {height = 24, source, itsOriSrc = false} = props;
    let src = getSrc(record, source, itsOriSrc);
    return (
        <video
            src={src}
            controls
            width="auto"
            height={height}
        >
        </video>
    )
}
//-----------------------------------------
const ImgBlock = props => {
    const {
        maxSize, source, accept, label,
        defaultValue, placeholder, formData, heightImg
    } = props

    const formEdit = useForm();
    const [stImg, setStImg] = React.useState('');
    const newName = `${source}_raw_file`

    useEffect(() => {
        setStImg(get(formData, source, ''))
        set(formData, newName, {})
    }, []);// eslint-disable-line
    useEffect(() => {
        formEdit.change(newName, {src: stImg})
    }, [stImg]);// eslint-disable-line
    useEffect(() => {
        if (formData[newName] === null)
            formEdit.change(source, null)
        else {
            const {rawFile} = formData[newName] || {};
            if (rawFile) formEdit.change(source, formData[newName])
        }
    }, [formData[newName]]);// eslint-disable-line


    return (
        <Box flex={1} p={'1em'}>
            <ImageInput
                style={{float: 'right'}}
                maxSize={maxSize}
                source={newName}
                label={label}
                accept={accept}
                //validate={required()}
                defaultValue={defaultValue}
                placeholder={placeholder}
            >
                {/*<ImageFieldComp source={source} height={240} width={'auto'} itsOriSrc={true}/>*/}
                <ImageFieldComp source={'src'} title="title" height={heightImg} width={'auto'}/>
            </ImageInput>
        </Box>

    )
}
export const ImageInputComp = (props) => {
    return (
        <FormDataConsumer>
            {({formData, ...rest}) => {
                return (
                    <ImgBlock
                        {...props}
                        formData={formData}
                    />
                )
            }}
        </FormDataConsumer>
    )
}
//-----------------------------------------

export const ImageFieldComp = (props) => {
    const record = useRecordContext(props);
    const {width = 64, height, avatar, source, alt = '', itsOriSrc = false} = props;
    const src = getSrc(record, source, itsOriSrc);
    //let src = get(record, source, null);

    const transformedSrc = useMemo(() => {
        if (!src) return;

        if (!isAbsoluteUrl(src)) {
            return `${process.env.REACT_APP_API_URL}/files_media/${src}`;
        }

        return src

    }, [src])

    if (transformedSrc) {
        return (
            <img
                alt={alt}
                style={{borderRadius: avatar ? "50%" : "4px"}}
                src={transformedSrc}
                width={width}
                height={avatar
                    ? width
                    : height
                        ? height
                        : "auto"}
            >
            </img>
        )
    } else return null;
}
export const ButtonFilterAside = (props) => {
    const translate = useTranslate();
    const {showAsside} = props;
    const [nameButton, SetNameButton] = React.useState(translate(showAsside ? 'myLocal.hide_filter' : 'myLocal.show_filter'));
    const [visibleButton, SetVisibleButton] = React.useState(showAsside);
    const handleClick = (url) => {
        //console.log('url===1======>', getCodeFromUrl(url))
        const bl = document.getElementById('aside_card');
        if (bl) {
            if (visibleButton) {
                bl.style.width = '0'
                SetVisibleButton(false)
                SetNameButton(translate('myLocal.show_filter'));
                bl.animate([
                    // keyframes
                    {width: '12em'},
                    {width: '0'},
                ], {
                    // timing options
                    duration: 300,
                    //iterations: Infinity
                })
            } else {
                bl.style.width = '12em'
                SetVisibleButton(true)
                SetNameButton(translate('myLocal.hide_filter'));
                bl.animate([
                    // keyframes
                    {width: '0'},
                    {width: '12em'},
                ], {
                    // timing options
                    duration: 300,
                    //iterations: Infinity
                })
            }
        }
    };

    return (
        <Button onClick={handleClick} color={"primary"}>{nameButton}</Button>
    );
}

async function readFileAsDataURL(file) {
    const result_base64 = await new Promise((resolve) => {
        let fileReader = new FileReader();
        fileReader.onload = (e) => resolve(fileReader.result);
        fileReader.readAsDataURL(file);
    });

    //console.log(result_base64); // aGV5IHRoZXJl...

    return result_base64;
}

export const transformBase64 = async (data) => {
    //async function transform(data) {
    let dt = data;
    for (const [key, value] of Object.entries(data)) {
        const {rawFile} = value || {};
        if (rawFile && rawFile instanceof File) {
            const {type} = rawFile || {};
            let dataURL = await readFileAsDataURL(rawFile);
            dt[key] = {rawFile: {type: type}, src: dataURL}
        }
    }
    //console.log('it=========>', dt)
    return dt;
}


// export default DateFieldComp;
// export default NumberFieldComp;
