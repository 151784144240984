import * as React from 'react';
import {
  FilterButton,
  ListContextProvider,
  ListToolbar,
  TopToolbar,
  useTranslate,
  useList, FilterLiveSearch
} from 'react-admin';

import {
  Grid,
  Box,
  Paper
} from '@mui/material';

import {HTML5Backend} from 'react-dnd-html5-backend';
import {DndProvider, MouseTransition, TouchTransition} from 'react-dnd-multi-backend';
import {TouchBackend} from 'react-dnd-touch-backend';
import CategoriesDnd from '../../_common/CategoriesDnd';
import CategoriesTreeProvider from '../../_common/_providers/CategoriesTreeProvider';


const ProductsGroupList = ({...props}) => {
  const translate = useTranslate();
  const listContext = useList({})


  const HTML5toTouchCustom = {
    backends: [
      {
        id: 'html5',
        backend: HTML5Backend,
        transition: MouseTransition,
        preview: true,
      },
      {
        id: 'touch',
        backend: TouchBackend,
        options: {
          enableMouseEvents: true,
          touchSlop: 5,
          delayTouchStart: 100,
        },
        preview: true,
        transition: TouchTransition,
      },
    ],
  };
  const ListActions = () => (
    <TopToolbar>
      <FilterButton/>
    </TopToolbar>
  );

  const primarySearchSource = 'primarySearch';
  const secondarySearchSource = 'secondarySearch'

    return (
        <Box mt={4}>


          <DndProvider options={HTML5toTouchCustom}>
              <Grid container spacing={4}>
                <Grid item xs={12} md={6}>
                  <ListContextProvider value={listContext}>

                    <ListToolbar
                      actions={<ListActions/>}
                      filters={[
                        <FilterLiveSearch source={primarySearchSource} alwaysOn/>
                      ]}
                    />

                  <CategoriesTreeProvider searchSource={primarySearchSource}>
                    <Paper sx={{maxHeight: 700, overflow: 'auto'}}>
                      <Box p={2}>
                        <CategoriesDnd/>
                      </Box>
                    </Paper>
                  </CategoriesTreeProvider>
                  </ListContextProvider>
                </Grid>


                <Grid item xs={12} md={6}>
                  <ListContextProvider value={listContext}>

                    <ListToolbar
                      actions={<ListActions/>}
                      filters={[
                        <FilterLiveSearch source={secondarySearchSource} alwaysOn/>
                      ]}
                    />

                    <CategoriesTreeProvider searchSource={secondarySearchSource}>
                      <Paper sx={{maxHeight: 700, overflow: 'auto'}}>
                          <Box p={2}>
                            <CategoriesDnd/>
                          </Box>
                      </Paper>
                    </CategoriesTreeProvider>

                  </ListContextProvider>
                </Grid>

              </Grid>
          </DndProvider>
        </Box>
    );
}

export default ProductsGroupList;
