import React, {useEffect} from 'react'
import {useCreate, useTranslate, useUpdate} from 'react-admin';
import {cloneDeep, get, isEmpty, map} from 'lodash';
import CategoryDndItem from './component/CategoryDndItem';
import {TreeView} from '@mui/x-tree-view/TreeView';
import useGetContext from '../../_hooks/useGetContext';
import {CategoriesTreeContext} from '../_providers/CategoriesTreeProvider';
import {Box, Stack, Typography, Tooltip} from '@mui/material';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';

export default function CategoriesDnd() {

  const translate = useTranslate();

  const [updateCategory] = useUpdate();
  const [create] = useCreate('dProductsGroupRelations');

  const { tree, refetchTree, isLoadingTree, isLoadedTree} = useGetContext(CategoriesTreeContext)

  const handleDrop = async (source, target, {isDirect = false, isAlternative}) => {

    const cloneTree = cloneDeep(tree);

    const parent = get(cloneTree, target?.parentPath);

    parent.sub_items = [source, ...parent.sub_items]

    if (isDirect) {
      await updateCategory('dProductsGroup', source?.product_group_id, {parent: target?.product_group_id}, {})
    }

    if (isAlternative) {
      await create('dProductsGroupRelations', {
        product_group_parent: target?.product_group_id,
        product_group_child: source?.product_group_id
      } )
    }

    refetchTree()
  }


  return (
    <React.Fragment>
      {isLoadedTree && isEmpty(tree)
        ? <Typography variant='body1'>
            {translate('ra.navigation.no_results')}
          </Typography>
        : <Box>
          <Stack
            pl={5}
            pr={5}
            pb={2}
            direction='row'
            alignItems='center'
            justifyContent='space-between'
            spacing={2}
          >
            <Typography variant='subtitle1' sx={{fontWeight: 600}}>Name</Typography>

            <Stack sx={{minWidth: 130}} direction='row' spacing={1}>
              <Tooltip title={translate('Hide category and its products')}>
                <PersonOutlineIcon/>
              </Tooltip>
              <Tooltip title={translate('Display On Main Page')}>
                <HomeOutlinedIcon/>
              </Tooltip>
              <Tooltip title={translate('Display type')}>
                <MenuOutlinedIcon/>
              </Tooltip>
              <Tooltip title={translate('Use as Filter in listing')}>
                <FilterAltOutlinedIcon/>
              </Tooltip>
              <div/>
            </Stack>
          </Stack>

          <TreeView
            onFocusCapture={e => e.stopPropagation()}
            disableSelection
            disabledItemsFocusable
          >
            {map(tree, (categoryItem, key) => <CategoryDndItem
              handleDrop={handleDrop}
              parentPath={[key]}
              key={key}
              dragItem={categoryItem}/>)}
          </TreeView>
        </Box>}
  </React.Fragment>
  )
}